import _get from 'lodash/get';

import {
  createTldFilter,
  getTldListById,
  getTldFiltersInfo,
  editTldFilter,
  deleteTldList,
} from '../../api/tldFilterLists';
import {get, makeUrl} from '../../utils/fetcher';
import {METHODS} from '../../constants';

export const types = {
  SET_ALL_TLDS: 'SET_ALL_TLDS',
  SET_TLD_FILTERS_INFO: 'SET_TLD_FILTERS_INFO',
  SET_SELECTED_TLD_FILTER: 'SET_SELECTED_TLD_FILTER',
  RESET_SELECTED_TLD_FILTER: 'RESET_SELECTED_TLD_FILTER',
  SET_POLICIES_ASSIGNED_TO_TLD_FILTER: 'SET_POLICIES_ASSIGNED_TO_TLD_FILTER',
};

export function getAllTlds() {
  const url = makeUrl(METHODS.GET_ALL_TLDS);
  return (dispatch) =>
    get(url, dispatch)
      .then((response) => {
        if (response && response.data) {
          const data = response.data;
          dispatch({
            type: types.SET_ALL_TLDS,
            data,
          });
        }
      });
}

export function createList(tldFilter) {
  const data = {
    name: _get(tldFilter, 'name', ''),
    description: _get(tldFilter, 'description', ''),
    allowedTldList: _get(tldFilter, 'allowedTldList', []),
  };
  return (dispatch) => dispatch(createTldFilter(data)).then((selectedTldList) => {
    dispatch({
      type: types.SET_SELECTED_TLD_FILTER,
      selectedTldList,
    });
    return selectedTldList.id;
  });
}

export function editList(tldFilter) {
  const data = {
    id: _get(tldFilter, 'id', ''),
    name: _get(tldFilter, 'name', ''),
    description: _get(tldFilter, 'description', ''),
    allowedTldList: _get(tldFilter, 'allowedTldList', []),
  };
  return (dispatch) => dispatch(editTldFilter(data)).then((selectedTldList) => {
    dispatch({
      type: types.SET_SELECTED_TLD_FILTER,
      selectedTldList,
    });
    return selectedTldList.id;
  });
}

export function getTldListsInfo() {
  return (dispatch) => dispatch(getTldFiltersInfo()).then((tldFiltersInfo) =>
    dispatch({
      type: types.SET_TLD_FILTERS_INFO,
      tldFiltersInfo,
    }));
}

export function getListById(id) {
  return (dispatch) => dispatch(getTldListById(id)).then((selectedTldList) =>
    dispatch({
      type: types.SET_SELECTED_TLD_FILTER,
      selectedTldList,
    }));
}

export function deleteList(id) {
  return (dispatch) => dispatch(deleteTldList({id}));
}

export function resetSelectedTldFilter() {
  return (dispatch) =>
    dispatch({
      type: types.RESET_SELECTED_TLD_FILTER,
    });
}

export function getPoliciesAssigned(tldFilterId) {
  const url = makeUrl(METHODS.GET_POLICIES_BY_TLD_FILTER, {tldFilterId});
  return (dispatch) =>
    get(url, dispatch)
      .then((response) => {
        if (response && response.data) {
          const policiesAssigned = response.data;
          dispatch({
            type: types.SET_POLICIES_ASSIGNED_TO_TLD_FILTER,
            policiesAssigned,
          });
        }
      });
}
