import {CATEGORIES, CATEGORY_TYPES, PERIODS, TLD_TYPES} from '../../constants';

export const ROWS_OPTIONS = [50, 100, 500, 1000];

export const DOMAIN_TYPES = {
  DOMAIN: 'domain',
  FQDN: 'fqdn'
};

export const MIN_ROWS_OPTION = ROWS_OPTIONS[0];
export const MAX_ROWS_OPTION = ROWS_OPTIONS[ROWS_OPTIONS.length - 1];

export const DEFAULT_SEARCH_OPTIONS = {
  rows: MIN_ROWS_OPTION,
  result: null,
  period: PERIODS.LAST_HOUR,
  domainName: '',
  parentDomain: '',
};

export const TABS = {
  DOMAIN: 'domain',
  TLD: 'tld',
  CATEGORY: 'category',
  RECORD_TYPE: 'recordType',
};

export const HIDE_ROWS_SELECTION = [TABS.RECORD_TYPE, TABS.CATEGORY];
export const HIDE_RESULT_SELECTION = [];
export const HIDE_NAME_SEARCH = [TABS.RECORD_TYPE, TABS.CATEGORY, TABS.TLD];
export const HIDE_DOMAIN_TYPE_SELECTION = [TABS.RECORD_TYPE, TABS.CATEGORY, TABS.TLD];

export const NAME_SEARCH_PROPS = {
  [TABS.DOMAIN]: {
    [DOMAIN_TYPES.DOMAIN]: {
      name: 'parentDomain',
      viewName: 'Domain Name',
    },
    [DOMAIN_TYPES.FQDN]: {
      name: 'domainName',
      viewName: 'FQDN',
    },
  },
};

export const FILTERS = {
  SITE: 'site',
  NETWORK: 'network',
  PROFILE: 'profile',
  CLIENT: 'client',
  USER: 'user',
  CATEGORY_TYPE: 'categoryType',
  CATEGORY_SUBTYPE: 'categorySubtype',
  CATEGORY: 'category',
  POLICY: 'policy',
  TLD_TYPE: 'tldType',
  RECORD_TYPE: 'recordType',
  DOMAIN_NAME: 'domainName',
  NETWORK_IP: 'networkIP',
  SUBNET_IP: 'subnetIP',
};

export const COMPONENT_TYPES = {
  DROPDOWN: 'dropdown',
  MULTIDROPDOWN: 'multidropdown',
  INPUT: 'input',
  AUTOCOMPLETE: 'autocomplete',
};

export const WB_LIST_TYPE = {
  ALLOW: 'allow',
  BLOCK: 'block',
};

export const INSIGHTS_LOGS_TABLE_HEADERS_LOCAL_STORAGE_KEY = 'domain_logs_table_headers';

export const LIMIT_LOAD_LOGS = 50;

export const LIMIT_LOAD_CLIENTS = 10000;

export const MIN_LOG_TABLE_ROWS = 10;

export const makeOptions = (items, withAll = false) => {
  const options = [];
  if (withAll) {
    options.push({
      id: null,
      name: 'All',
    });
  }
  options.push(...items.map((i) => ({
    id: i,
    name: i,
  })));
  return options;
};

export const categoryOptions = makeOptions(Object.values(CATEGORIES), true);
export const categoryTypeOptions = makeOptions(Object.values(CATEGORY_TYPES), true);
export const tldTypeOptions = makeOptions(Object.values(TLD_TYPES), false);

export const RECORD_TYPES = [
  {id: '0', name: 'ENT'},
  {id: '1', name: 'A'},
  {id: '2', name: 'NS'},
  {id: '3', name: 'CNAME'},
  {id: '4', name: 'SOA'},
  {id: '5', name: 'MR'},
  {id: '6', name: 'WKS'},
  {id: '7', name: 'PTR'},
  {id: '8', name: 'HINFO'},
  {id: '9', name: 'MINFO'},
  {id: '10', name: 'MX'},
  {id: '11', name: 'TXT'},
  {id: '12', name: 'RP'},
  {id: '13', name: 'AFSDB'},
  {id: '14', name: 'SIG'},
  {id: '15', name: 'KEY'},
  {id: '16', name: 'AAAA'},
  {id: '17', name: 'LOC'},
  {id: '18', name: 'SRV'},
  {id: '19', name: 'NAPTR'},
  {id: '20', name: 'KX'},
  {id: '21', name: 'CERT'},
  {id: '22', name: 'A6'},
  {id: '23', name: 'DNAME'},
  {id: '24', name: 'OPT'},
  {id: '25', name: 'DS'},
  {id: '26', name: 'SSHFP'},
  {id: '27', name: 'IPSECKEY'},
  {id: '28', name: 'RRSIG'},
  {id: '29', name: 'NSEC'},
  {id: '30', name: 'DNSKEY'},
  {id: '31', name: 'DHCID'},
  {id: '32', name: 'NSEC3'},
  {id: '33', name: 'NSEC3PARAM'},
  {id: '34', name: 'TLSA'},
  {id: '35', name: 'RKEY'},
  {id: '36', name: 'CDS'},
  {id: '37', name: 'CDNSKEY'},
  {id: '38', name: 'OPENPGPKEY'},
  {id: '39', name: 'SPF'},
  {id: '40', name: 'EUI48'},
  {id: '41', name: 'EUI64'},
  {id: '42', name: 'TKEY'},
  {id: '43', name: 'TSIG'},
  {id: '44', name: 'IXFR'},
  {id: '45', name: 'AXFR'},
  {id: '46', name: 'MAILB'},
  {id: '47', name: 'MAILA'},
  {id: '48', name: 'ANY'},
  {id: '49', name: 'URI'},
  {id: '50', name: 'CAA'},
  {id: '51', name: 'DLV'},
  {id: '52', name: 'ADDR'},
  {id: '53', name: 'ALIAS'},
  {id: '54', name: 'MD'},
  {id: '55', name: 'MF'},
  {id: '56', name: 'MB'},
  {id: '57', name: 'MG'},
  {id: '58', name: 'NULL'},
  {id: '59', name: 'X25'},
  {id: '60', name: 'ISDN'},
  {id: '61', name: 'RT'},
  {id: '62', name: 'NSAP'},
  {id: '63', name: 'PX'},
  {id: '64', name: 'GPOS'},
  {id: '65', name: 'SINK'},
  {id: '66', name: 'APL'},
  {id: '67', name: 'HIP'},
  {id: '68', name: 'NINFO'},
  {id: '69', name: 'TALINK'},
  {id: '70', name: 'CSYNC'},
  {id: '71', name: 'UNSPEC'},
  {id: '72', name: 'NID'},
  {id: '73', name: 'L32'},
  {id: '74', name: 'L64'},
  {id: '75', name: 'LP'},
  {id: '76', name: 'DOA'},
  {id: '77', name: 'TA'},
  {id: '78', name: 'NSAP-PTR'},
  {id: '79', name: 'NXT'},
  {id: '80', name: 'EID'},
  {id: '81', name: 'NIMLOC'},
  {id: '82', name: 'ATMA'},
  {id: '83', name: 'SMIMEA'},
  {id: '84', name: 'ZONEMD'},
  {id: '85', name: 'SVCB'},
  {id: '86', name: 'HTTPS'},
  {id: '87', name: 'UINFO'},
  {id: '88', name: 'UID'},
  {id: '89', name: 'GID'},
];

export const widgetKeys = Object.values(TABS).reduce((acc, value) => {
  acc[value] = `insights__${value}`;
  return acc;
}, {});

export const COMPONENTS = {
  INSIGHTS: 'insights',
  DOMAINS: 'domain',
  FQDNS: 'fqdn',
  LOGS: 'logs',
  INSPECT_RESPONSE: 'response',
};
