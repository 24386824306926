import React from 'react';
import PropTypes from 'prop-types';
import {I18n} from 'react-redux-i18n';

import {withStyles} from '@material-ui/core/styles';

import {
  GroupStatsCard,
  CardStats,
} from '../index';
import {LoadingLayout} from '../../layouts-common';

import style from './style';

function OrgsAndSitesStats(props) {
  const {
    classes,
    sites,
    orgs,
    widgetKey,
  } = props;
  return (
    <GroupStatsCard
        groupName={I18n.t('dashboard.orgsAndSitesStats.title')}
        customCardClassName="card_status"
        content={(
          <LoadingLayout widgetKey={widgetKey}>
            <div className={classes.container}>
              <div className={classes.item}>
                <CardStats
                  description={I18n.t('dashboard.orgsAndSitesStats.orgs')}
                  borderTopColor="black"
                  value={orgs}
                />
              </div>
              <div className={classes.item}>
                <CardStats
                  description={I18n.t('dashboard.orgsAndSitesStats.sites')}
                  borderTopColor="black"
                  value={sites}
                />
              </div>
            </div>
          </LoadingLayout>
        )}
    />
  );
}

OrgsAndSitesStats.propTypes = {
  classes: PropTypes.object.isRequired,
  orgs: PropTypes.number,
  sites: PropTypes.number,
  widgetKey: PropTypes.string.isRequired,
};
OrgsAndSitesStats.defaultProps = {
  orgs: 0,
  sites: 0,
};

export default withStyles(style)(OrgsAndSitesStats);
