import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {I18n} from 'react-redux-i18n';
import classNames from 'classnames';
import {v4 as uuid} from 'uuid';
import _cloneDeep from 'lodash/cloneDeep';
import _isEqual from 'lodash/isEqual';

import {
  Manager,
  Popper,
  Target,
} from 'react-popper';

import {withStyles} from '@material-ui/core/styles';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import IconButton from '@material-ui/core/IconButton';
import Paper from '@material-ui/core/Paper';

import SortableColumnsList from './SortableColumnsList';

import {ViewList} from '../../icons/index';

import style from './style';

class TableColumnsPicker extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
    };
  }

  handleClick = () => {
    this.setState((prevState) => ({
      isOpen: !prevState.isOpen,
    }));
  };

  handleClose = () => {
    this.setState({isOpen: false});
  };

  handleCheckboxChange = (e) => {
    const {headers} = this.props;
    const updatedHeaders = _cloneDeep(headers);
    updatedHeaders.forEach((item) => {
      if (_isEqual(item.accessor, e.target.name)) {
        item.isShow = e.target.checked;
      }
    });
    this.props.onChange(updatedHeaders);
  };

  render() {
    const {classes} = this.props;
    const {isOpen} = this.state;

    const managerStyle = {display: 'inline-block'};
    const growStyle = {transformOrigin: '0 0 0'};
    return (
      <ClickAwayListener onClickAway={this.handleClose}>
        <Manager style={managerStyle}>
          <Target>
            <IconButton
              color="inherit"
              className={classes.picker__button}
              onClick={this.handleClick}
            >
              <ViewList />
            </IconButton>
          </Target>
          <Popper
            placement="bottom-start"
            eventsEnabled={isOpen}
            className={classNames(
              classes.popper_responsive,
              {
                [classes.popper_close]: !isOpen,
              },
            )}
          >
            <Grow
              in={isOpen}
              id={uuid()}
              style={growStyle}
            >
              <Paper className={classes.columnPicker}>
                <div className={classes.columnPicker__header}>
                  {I18n.t('tableColumnPicker.title')}
                </div>
                <SortableColumnsList
                  classes={classes}
                  items={this.props.headers}
                  handleMove={this.props.onChange}
                  handleCheckboxChange={this.handleCheckboxChange}
                />
              </Paper>
            </Grow>
          </Popper>
        </Manager>
      </ClickAwayListener>
    );
  }
}

TableColumnsPicker.propTypes = {
  classes: PropTypes.object.isRequired,
  headers: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
};

TableColumnsPicker.defaultProps = {};

export default withStyles(style)(TableColumnsPicker);
