import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {I18n} from 'react-redux-i18n';
import classNames from 'classnames';
import DateTimePicker from 'react-datetime';
import moment from 'moment-timezone';
import _isNil from 'lodash/isNil';
import _isEqual from 'lodash/isEqual';
import _isEmpty from 'lodash/isEmpty';

import Box from '@material-ui/core/Box';
import {withStyles} from '@material-ui/core/styles';

import {CustomButton, CustomInput} from '../../material-dashboard-pro-react/components';
import {Dropdown} from '../index';
import {calculatePeriod} from './utils';
import {formatMoment} from '../../utils/dateTime';
import {isNonNegativeReal} from '../../utils/validators';

import style from './style';

class UsageBillingSettings extends Component {
  static initialState = {
    isDatePickerShown: false,
    validation: {
      queryCost: true,
      seatCost: true,
    },
  };

  static last30Days = 'last30Days';

  static invoiceDate = 'invoiceDate';

  constructor(props) {
    super(props);
    this.state = {...UsageBillingSettings.initialState};
  }

  componentDidMount() {
    const periodName = this.periodOptions[0].name;
    this.props.onChangePeriod(
      0,
      calculatePeriod(periodName),
    );
    this.toggleDatePicker(periodName);
  }

  get periodOptions() {
    return [
      {
        name: UsageBillingSettings.last30Days,
        label: I18n.t('organizations.usageBilling.settings.periods.last30Days'),
      },
      {
        name: UsageBillingSettings.invoiceDate,
        label: I18n.t('organizations.usageBilling.settings.periods.invoiceDate'),
      },
    ];
  }

  handleChangePeriod = (e) => {
    const {onChangePeriod, invoiceDate} = this.props;
    const newPeriodIndex = e.target.value;
    const newPeriodName = this.periodOptions[newPeriodIndex].name;
    onChangePeriod(
      newPeriodIndex,
      calculatePeriod(newPeriodName, invoiceDate),
    );
    this.toggleDatePicker(newPeriodName);
  };

  handleChangeCost = (e) => {
    const {name, value} = e.target;
    this.setValidation(name, true);
    this.props.onChangeCost(name, value);
  };

  handleChangeDate = (date) => {
    const {selectedPeriodIndex, onChangeInvoiceDate, onChangePeriod} = this.props;
    onChangeInvoiceDate(date);
    onChangePeriod(
      selectedPeriodIndex,
      calculatePeriod(UsageBillingSettings.invoiceDate, date),
    );
  };

  handleClickCalculate = () => {
    const {onClickCalculate} = this.props;

    if (this.validate()) {
      onClickCalculate();
    }
  };

  handleClickExport = () => {
    const {onClickExportCSV} = this.props;
    if (this.validate()) {
      onClickExportCSV();
    }
  };

  toggleDatePicker = (periodName) => {
    this.setState({
      isDatePickerShown: _isEqual(periodName, UsageBillingSettings.invoiceDate),
    });
  };

  setValidation = (name, flag) => {
    this.setState((prevState) => ({
      validation: {
        ...prevState.validation,
        [name]: flag,
      },
    }));
  };

  validateCost = (cost) => !_isEmpty(cost) && isNonNegativeReal(cost);

  validate = () => {
    const {queryCost, seatCost} = this.props;
    const fields = [
      {
        name: 'queryCost',
        value: queryCost,
      },
      {
        name: 'seatCost',
        value: seatCost,
      },
    ];
    const invalidFields = [];

    const isValid = fields.reduce((onlyOneValid, field) => {
      const fieldValid = this.validateCost(field.value);
      if (!fieldValid) {
        invalidFields.push(field.name);
      }
      return onlyOneValid || fieldValid;
    }, false);

    invalidFields.forEach((field) => this.setValidation(field.name, false));

    return isValid;
  };

  isValidDate = (date) => (date.isAfter(moment().subtract(45, 'days'))
    && date.isSameOrBefore(moment.now()));

  renderActionBlock = () => {
    const {
      classes,
      isCalculateEnabled,
      isExportCSVEnabled,
    } = this.props;

    return (
      <Box className={classes.actionBlock}>
        <CustomButton
          color="secondary"
          customClasses="usage-billing-settings__button"
          disabled={!isCalculateEnabled}
          onClick={this.handleClickCalculate}
          size="sm"
        >
          {I18n.t('organizations.usageBilling.settings.buttons.calculate')}
        </CustomButton>
        <CustomButton
          customClasses="usage-billing-settings__button"
          disabled={!isExportCSVEnabled}
          onClick={this.handleClickExport}
          size="sm"
        >
          {I18n.t('organizations.usageBilling.settings.buttons.exportCSV')}
        </CustomButton>
      </Box>
    );
  };

  renderCostBlock = () => {
    const {classes, queryCost, seatCost} = this.props;
    const {validation} = this.state;
    const queryCostInputProps = {
      name: 'queryCost',
      value: queryCost,
      onChange: this.handleChangeCost,
    };
    const seatCostInputProps = {
      name: 'seatCost',
      value: seatCost,
      onChange: this.handleChangeCost,
    };
    const formControlProps = {};

    return (
      <Box className={classes.costBlock}>
        <CustomInput
          name="queryCost"
          labelText={I18n.t('organizations.usageBilling.settings.labels.queryCost')}
          type="text"
          inputProps={queryCostInputProps}
          formControlProps={formControlProps}
          error={!validation.queryCost}
        />
        <CustomInput
          name="seatCost"
          labelText={I18n.t('organizations.usageBilling.settings.labels.seatCost')}
          type="text"
          inputProps={seatCostInputProps}
          formControlProps={formControlProps}
          error={!validation.seatCost}
        />
      </Box>
    );
  };

  renderPeriodSettings = () => {
    const {classes, period, invoiceDate, selectedPeriodIndex} = this.props;
    const {isDatePickerShown} = this.state;
    const periodCovers = !_isNil(period)
      // eslint-disable-next-line max-len
      ? `${I18n.t('organizations.usageBilling.settings.labels.periodCovers')}: `
        + `${formatMoment(period.from)} - ${formatMoment(period.till)}`
      : null;
    const datePickerClasses = classNames(
      classes.datePicker,
      {[classes.datePicker_notVisible]: !isDatePickerShown},
    );
    const inputProps = {};

    return (
      <Box className={classes.periodSettings}>
        <Box className={classes.dateTime}>
          <Dropdown
            className={classes.periodSelector}
            dropdownName="period"
            label={I18n.t('organizations.usageBilling.settings.labels.period')}
            options={this.periodOptions}
            selectedItemIndex={selectedPeriodIndex}
            customSelectClass="dropdown__select_clientProfile"
            onChangeValue={this.handleChangePeriod}
          />
          <DateTimePicker
            className={datePickerClasses}
            dateFormat={true}
            timeFormat={false}
            inputProps={inputProps}
            defaultValue={invoiceDate}
            value={invoiceDate}
            isValidDate={this.isValidDate}
            onChange={this.handleChangeDate}
          />
        </Box>
        <Box>
          {periodCovers}
        </Box>
      </Box>
    );
  };

  render() {
    const {
      classes,
    } = this.props;

    return (
      <Box className={classes.root}>
        {this.renderPeriodSettings()}
        <Box className={classes.actionsAndCosts} >
          {this.renderCostBlock()}
          {this.renderActionBlock()}
        </Box>
      </Box>
    );
  }
}

UsageBillingSettings.propTypes = {
  classes: PropTypes.object.isRequired,
  period: PropTypes.object,
  selectedPeriodIndex: PropTypes.number.isRequired,
  queryCost: PropTypes.string,
  seatCost: PropTypes.string,
  invoiceDate: PropTypes.object,
  isCalculateEnabled: PropTypes.bool,
  isExportCSVEnabled: PropTypes.bool,
  onChangePeriod: PropTypes.func.isRequired,
  onChangeInvoiceDate: PropTypes.func.isRequired,
  onChangeCost: PropTypes.func.isRequired,
  onClickCalculate: PropTypes.func.isRequired,
  onClickExportCSV: PropTypes.func.isRequired,
};

UsageBillingSettings.defaultProps = {
  period: null,
  queryCost: '',
  seatCost: '',
  invoiceDate: null,
  isCalculateEnabled: false,
  isExportCSVEnabled: false,
};

export default withStyles(style)(UsageBillingSettings);
