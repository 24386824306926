import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {I18n} from 'react-redux-i18n';
import _isNil from 'lodash/isNil';

import {withStyles} from '@material-ui/core/styles';

import {v4 as uuid} from 'uuid';
import _isEqual from 'lodash/isEqual';
import {
  CustomInput,
} from '../../material-dashboard-pro-react/components';

import style from './style';

import {
  ButtonDeleteEntity,
  RenderOrEmpty,
  Switch,
} from '../../components/index';

import {STATES_ENTITY} from '../../constants';
import {RESPONSE_MAX_LEN, RESPONSE_ROW_COUNT} from './constants';
import {
  setEditableBlockPageInfo,
  resetBlockPageEditFormData,
  setValidationResultBlockPageInfo,
  updateBlockPageInfo,
  setDefaultBlockPageFormData,
} from './action';

import noImage from './img/noImage.png';
import loading from './img/loading.gif';

import {
  isEmail,
  isNotEmpty,
  validate,
} from '../../utils/validators';

class BlockPageEditForm extends Component {
  static initialLocalState = {
    validation: {
      name: true,
      contentTitle: true,
      contentHeader: true,
      contentResponse: true,
      contentNonDefaultEmail: true,
      contentLogo: true,
    },
  };

  static shouldUpdateBlockPageObject(prevProps, props) {
    return !_isEqual(prevProps.blockPage, props.blockPage);
  }

  static shouldResetFormData(prevProps, props) {
    return !_isEqual(prevProps.isEditMode, props.isEditMode)
      && _isEqual(props.blockPageState, STATES_ENTITY.EDITING_CANCELED);
  }

  constructor(props) {
    super(props);
    this.state = {...BlockPageEditForm.initialLocalState};
  }

  componentDidMount() {
    const {isEditMode} = this.props;
    this.props.setEditableBlockPageInfo(this.props.blockPage);
    if (isEditMode) this.props.setDefaultBlockPageFormData();
  }

  shouldComponentUpdate(nextProps) {
    return !_isEqual(this.props.blockPage, nextProps.blockPage)
      || !_isEqual(this.props.isEditMode, nextProps.isEditMode)
      || !_isEqual(this.props.editableBlockPageInfo, nextProps.editableBlockPageInfo);
  }

  componentDidUpdate(prevProps) {
    if (BlockPageEditForm.shouldUpdateBlockPageObject(prevProps, this.props)
      || BlockPageEditForm.shouldResetFormData(prevProps, this.props)
    ) {
      this.props.setEditableBlockPageInfo(this.props.blockPage);
    }
  }

  handleChangeInput = (e) => {
    const {editableBlockPageInfo} = this.props;

    const validationResult = this.validate({
      ...this.props.editableBlockPageInfo,
      [e.target.name]: e.target.value,
    });

    this.props.updateBlockPageInfo(
      {
        ...editableBlockPageInfo,
        [e.target.name]: e.target.value,
      },
      validationResult.isValid,
    );

    this.setState({
      validation: validationResult.validationState,
    });
  };

  handleChangeSwitchInput = (e) => {
    const {editableBlockPageInfo} = this.props;

    const validationResult = this.validate({
      ...this.props.editableBlockPageInfo,
      [e.target.name]: !editableBlockPageInfo[e.target.name],
    });

    this.props.updateBlockPageInfo(
      {
        ...editableBlockPageInfo,
        [e.target.name]: !editableBlockPageInfo[e.target.name],
      },
      validationResult.isValid,
    );

    this.setState({
      validation: validationResult.validationState,
    });
  };

  handleChangeReviewRequestsSwitchInput = (e) => {
    const {editableBlockPageInfo} = this.props;

    this.props.updateBlockPageInfo(
      {
        ...editableBlockPageInfo,
        contentHasNonDefaultSendEmail: false,
        contentHasUserEmailInput: false,
        [e.target.name]: !editableBlockPageInfo[e.target.name],
      },
    );
  };

  handleChangeImageInput = (e) => {
    const images = e.target.files;
    const {editableBlockPageInfo} = this.props;

    const validationResult = this.validate({
      ...this.props.editableBlockPageInfo,
      [e.target.name]: e.target.value,
    });

    if (images.length !== 0) {
      this.props.updateBlockPageInfo(
        {
          ...editableBlockPageInfo,
          [e.target.name]: images[0],
          contentLogoName: images[0].name,
        },
        validationResult.isValid,
      );
    }

    this.setState({
      validation: validationResult.validationState,
    });

    e.target.value = '';
  };

  handleClickButtonDeleteLogo = () => {
    const {editableBlockPageInfo} = this.props;

    const validationResult = this.validate({
      ...this.props.editableBlockPageInfo,
      contentLogo: null,
      contentLogoURL: '',
    });

    this.props.updateBlockPageInfo(
      {
        ...editableBlockPageInfo,
        contentLogoName: '',
        threatLogoName: '',
        contentLogoURL: '',
        threatLogoURL: '',
        contentLogo: null,
      },
      validationResult.isValid,
    );

    this.setState({
      validation: validationResult.validationState,
    });
  };

  // resetValidation = () => {
  //   this.props.setValidationResultBlockPageInfo(true);
  //   this.setState(BlockPageEditForm.initialLocalState);
  // };

  validate = (data) => validate(
    [
      {
        name: 'name',
        validationRules: [isNotEmpty],
      },
      {
        name: 'contentTitle',
        validationRules: [isNotEmpty],
      },
      {
        name: 'contentHeader',
        validationRules: [isNotEmpty],
      },
      {
        name: 'contentResponse',
        validationRules: [isNotEmpty],
      },
      {
        name: 'contentNonDefaultEmail',
        validationRules: data.contentHasNonDefaultSendEmail ? [isNotEmpty, isEmail] : [],
      },
      {
        name: 'contentLogo',
        validationRules: data.contentHasLogoInserted && !isNotEmpty(data.contentLogoURL)
          ? [isNotEmpty]
          : [],
      },
    ],
    data,
    {
      name: true,
      contentTitle: true,
      contentHeader: true,
      contentResponse: true,
      contentNonDefaultEmail: true,
      contentLogo: true,
    },
  );

  replaceImageLink = (error) => {
    error.target.src = loading;
  };

  render() {
    const {
      classes,
      editableBlockPageInfo,
      isEditMode,
      blockPage,
    } = this.props;

    const {validation} = this.state;

    const propsInput = {
      name: {
        disabled: !!(!isEditMode || blockPage.default),
        value: editableBlockPageInfo.name,
        name: 'name',
        onChange: this.handleChangeInput,
        type: 'text',
      },
      description: {
        disabled: !isEditMode,
        value: editableBlockPageInfo.description,
        name: 'description',
        onChange: this.handleChangeInput,
        type: 'text',
      },
      contentTitle: {
        disabled: !isEditMode,
        value: editableBlockPageInfo.contentTitle,
        name: 'contentTitle',
        onChange: this.handleChangeInput,
        type: 'text',
      },
      contentNonDefaultEmail: {
        disabled: !isEditMode || !editableBlockPageInfo.contentHasReviewRequests,
        value: editableBlockPageInfo.contentNonDefaultEmail,
        name: 'contentNonDefaultEmail',
        onChange: this.handleChangeInput,
        type: 'text',
      },
      contentLogoMessage: {
        disabled: !isEditMode,
        value: editableBlockPageInfo.contentLogoMessage,
        name: 'contentLogoMessage',
        onChange: this.handleChangeInput,
        type: 'text',
      },
      contentHeader: {
        disabled: !isEditMode,
        value: editableBlockPageInfo.contentHeader,
        name: 'contentHeader',
        onChange: this.handleChangeInput,
        type: 'text',
      },
      contentHasReviewRequests: {
        disabled: !isEditMode,
        onChange: this.handleChangeReviewRequestsSwitchInput,
      },
      contentShouldHideIPs: {
        disabled: !isEditMode,
        onChange: this.handleChangeSwitchInput,
      },
      contentHasLogoInserted: {
        disabled: !isEditMode,
        onChange: this.handleChangeSwitchInput,
      },
      contentHasNonDefaultSendEmail: {
        disabled: !isEditMode || !editableBlockPageInfo.contentHasReviewRequests,
        onChange: this.handleChangeSwitchInput,
      },
      contentHasUserEmailInput: {
        disabled: !isEditMode || !editableBlockPageInfo.contentHasReviewRequests,
        onChange: this.handleChangeSwitchInput,
      },
      contentLogo: {
        disabled: !isEditMode,
        type: 'file',
        name: 'contentLogo',
        disableUnderline: true,
        onChange: this.handleChangeImageInput,
        inputProps: {accept: '.jpeg,.png', style: {color: 'transparent'}, title: ''},
      },
      contentResponse: {
        disabled: !isEditMode,
        value: editableBlockPageInfo.contentResponse,
        name: 'contentResponse',
        onChange: this.handleChangeInput,
        type: 'text',
        multiline: true,
        disableUnderline: true,
        rows: RESPONSE_ROW_COUNT,
        max: RESPONSE_MAX_LEN,
        classes: {
          input: classes.form__input,
          root: (!isEditMode)
            ? classes.form__inputRoot_response_view
            : classes.form__inputRoot_response_edit,
          focused: classes.form__inputRoot_response_focused,
        },
      },
    };
    const propsFormControl = {
      fullWidth: true,
    };

    const labelProps = {
      response: {
        classes: {
          root: classes.form__formControl_response,
          shrink: classes.form__shrink_response,
        },
      },
      contentLogo: {
        shrink: true,
      },
    };

    const previewImageLink = blockPage.contentLogoURL ? blockPage.contentLogoURL : noImage;

    return (
      <form className={classes.form}>
        <div className={classes.form__block}>
          <div className={classes.form__item}>
            <CustomInput
              errorText={validation.name
                ? undefined
                : I18n.t('blockPage.errorMessages.blockPageNameIsNotValid')}
              error={!validation.name}
              labelText={I18n.t('blockPage.editForm.labels.name')}
              id={uuid()}
              formControlProps={propsFormControl}
              inputProps={propsInput.name}
            />
          </div>
          <div className={classes.form__item}>
            <CustomInput
              errorText={validation.contentTitle
                ? undefined
                : I18n.t('blockPage.errorMessages.blockPageContentTitleIsNotValid')}
              error={!validation.contentTitle}
              labelText={I18n.t('blockPage.editForm.labels.contentTitle')}
              id={uuid()}
              formControlProps={propsFormControl}
              inputProps={propsInput.contentTitle}
            />
          </div>
          <div className={classes.form__item}>
            <CustomInput
              errorText={validation.contentResponse
                ? undefined
                : I18n.t('blockPage.errorMessages.blockPageContentResponseIsNotValid')}
              error={!validation.contentResponse}
              labelText={I18n.t('blockPage.editForm.labels.contentResponse')}
              id={uuid()}
              formControlProps={propsFormControl}
              inputProps={propsInput.contentResponse}
              labelProps={labelProps.response}
            />
          </div>
        </div>
        <div className={classes.form__block}>
          <div className={classes.form__item}>
            <CustomInput
              labelText={I18n.t('blockPage.editForm.labels.description')}
              id={uuid()}
              formControlProps={propsFormControl}
              inputProps={propsInput.description}
            />
          </div>
          <div className={classes.form__item}>
            <CustomInput
              errorText={validation.contentHeader
                ? undefined
                : I18n.t('blockPage.errorMessages.blockPageContentHeaderIsNotValid')}
              error={!validation.contentHeader}
              labelText={I18n.t('blockPage.editForm.labels.contentHeader')}
              id={uuid()}
              formControlProps={propsFormControl}
              inputProps={propsInput.contentHeader}
            />
          </div>
          <div className={classes.form__switchBlock}>
            <div className={classes.form__item}>
              <Switch
                  isChecked={editableBlockPageInfo.contentHasReviewRequests}
                  label={I18n.t('blockPage.editForm.labels.contentHasReviewRequests')}
                  switchClassName="switch_inline"
                  name="contentHasReviewRequests"
                  labelFormClassName="formControl_oneLine"
                  labelClassName="switch__label_oneLine"
                  inputProps={propsInput.contentHasReviewRequests}
              />
            </div>
            <div className={classes.form__item}>
              <Switch
                isChecked={editableBlockPageInfo.contentShouldHideIPs}
                label={I18n.t('blockPage.editForm.labels.contentShouldHideIPs')}
                switchClassName="switch_inline"
                name="contentShouldHideIPs"
                labelFormClassName="formControl_oneLine"
                labelClassName="switch__label_oneLine"
                inputProps={propsInput.contentShouldHideIPs}
                disabled={!editableBlockPageInfo.contentShouldHideIPs}
              />
            </div>
            <div className={classes.form__item}>
              <Switch
                isChecked={editableBlockPageInfo.contentHasUserEmailInput}
                label={I18n.t('blockPage.editForm.labels.contentHasUserEmailInput')}
                switchClassName="switch_inline"
                name="contentHasUserEmailInput"
                labelFormClassName="formControl_oneLine"
                labelClassName="switch__label_oneLine"
                inputProps={propsInput.contentHasUserEmailInput}
                disabled={!editableBlockPageInfo.contentHasReviewRequests}
              />
            </div>
            <div className={classes.form__item}>
              <Switch
                isChecked={editableBlockPageInfo.contentHasNonDefaultSendEmail}
                label={I18n.t('blockPage.editForm.labels.contentHasNonDefaultSendEmail')}
                switchClassName="switch_inline"
                name="contentHasNonDefaultSendEmail"
                labelFormClassName="formControl_oneLine"
                labelClassName="switch__label_oneLine"
                inputProps={propsInput.contentHasNonDefaultSendEmail}
                disabled={!editableBlockPageInfo.contentHasReviewRequests}
              />
            </div>
          </div>
          <div className={classes.form__item}>
            <RenderOrEmpty isShow={this.props.editableBlockPageInfo.contentHasNonDefaultSendEmail}>
              <CustomInput
                errorText={validation.contentNonDefaultEmail
                  ? undefined
                  : I18n.t('blockPage.errorMessages.blockPageContentNonDefaultEmailIsNotValid')}
                error={!validation.contentNonDefaultEmail}
                labelText={I18n.t('blockPage.editForm.labels.contentNonDefaultEmail')}
                id={uuid()}
                formControlProps={propsFormControl}
                inputProps={propsInput.contentNonDefaultEmail}
              />
            </RenderOrEmpty>
          </div>
        </div>
        <div className={classes.form__block}>
          <div className={classes.form__switchBlock}>
            <div className={classes.form__item}>
              <Switch
                  isChecked={editableBlockPageInfo.contentHasLogoInserted}
                  label={I18n.t('blockPage.editForm.labels.contentHasLogoInserted')}
                  switchClassName="switch_inline"
                  name="contentHasLogoInserted"
                  labelFormClassName="formControl_oneLine"
                  labelClassName="switch__label_oneLine"
                  inputProps={propsInput.contentHasLogoInserted}
              />
            </div>
          </div>
          <div className={classes.form__item}>
            <RenderOrEmpty isShow={this.props.editableBlockPageInfo.contentHasLogoInserted}>
              <CustomInput
                labelText={I18n.t('blockPage.editForm.labels.contentLogoMessage')}
                id={uuid()}
                formControlProps={propsFormControl}
                inputProps={propsInput.contentLogoMessage}
              />
            </RenderOrEmpty>
          </div>
          <div className={classes.form__item}>
            <RenderOrEmpty isShow={this.props.editableBlockPageInfo.contentHasLogoInserted}>
              <div className={classes.form__imageUploadContainer}>
                <CustomInput
                  errorText={validation.contentLogo
                    ? undefined
                    : I18n.t('blockPage.errorMessages.blockPageContentLogoURLIsNotValid')}
                  error={!validation.contentLogo}
                  labelText={I18n.t('blockPage.editForm.labels.contentLogo')}
                  id={uuid()}
                  formControlProps={propsFormControl}
                  inputProps={propsInput.contentLogo}
                  labelProps={labelProps.contentLogo}
                />
                <RenderOrEmpty isShow={!_isNil(editableBlockPageInfo.contentLogoName)}>
                  <p>{this.props.editableBlockPageInfo.contentLogoName}</p>
                </RenderOrEmpty>
                <RenderOrEmpty isShow={_isNil(editableBlockPageInfo.contentLogoName)}>
                  <p>No file chosen</p>
                </RenderOrEmpty>
                <RenderOrEmpty isShow={!_isNil(editableBlockPageInfo.contentLogoName)}>
                  <ButtonDeleteEntity
                    text={I18n.t('blockPage.editForm.deleteLogo')}
                    onClick={this.handleClickButtonDeleteLogo}
                    disabled={!isEditMode}
                  />
                </RenderOrEmpty>
              </div>
            </RenderOrEmpty>
          </div>
          <RenderOrEmpty isShow={this.props.editableBlockPageInfo.contentHasLogoInserted}>
            <p className={classes.form__logoPreviewValue}>Preview of current block page logo:</p>
            <div className={classes.form__logoPreviewContainer}>
              <img src={previewImageLink} className={classes.form__logoPreview} alt="no icon is uploaded yet" onError={this.replaceImageLink} />
            </div>
          </RenderOrEmpty>
        </div>
      </form>

    );
  }
}

BlockPageEditForm.propTypes = ({
  classes: PropTypes.object.isRequired,
  blockPage: PropTypes.object.isRequired,
  blockPageState: PropTypes.string.isRequired,
  isEditMode: PropTypes.bool.isRequired,
  editableBlockPageInfo: PropTypes.object.isRequired,

  resetBlockPageEditFormData: PropTypes.func.isRequired,
  setEditableBlockPageInfo: PropTypes.func.isRequired,
  setDefaultBlockPageFormData: PropTypes.func.isRequired,
  setValidationResultBlockPageInfo: PropTypes.func.isRequired,
  updateBlockPageInfo: PropTypes.func.isRequired,
});

const mapStateToProps = (state) => ({
  blockPage: state.blockPageReducer.selectedBlockPage,
  blockPageState: state.blockPageReducer.blockPageState,
  isEditMode: state.blockPageReducer.isEditMode,

  editableBlockPageInfo: state.blockPageEditFormReducer.editableBlockPageInfo,
});

const mapDispatchToProps = (dispatch) => ({
  resetBlockPageEditFormData: bindActionCreators(resetBlockPageEditFormData, dispatch),
  setEditableBlockPageInfo: bindActionCreators(setEditableBlockPageInfo, dispatch),
  setValidationResultBlockPageInfo: bindActionCreators(setValidationResultBlockPageInfo, dispatch),
  updateBlockPageInfo: bindActionCreators(updateBlockPageInfo, dispatch),
  setDefaultBlockPageFormData: bindActionCreators(setDefaultBlockPageFormData, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(style)(BlockPageEditForm));
