import React from 'react';
import PropTypes from 'prop-types';
import _pullAt from 'lodash/pullAt';
import {withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {I18n} from 'react-redux-i18n';
import {updateEditOrganizationProfiles} from './action';
import {insertIntoSortedArray} from '../../utils/array';
import {SelectableEntityVerticalPairedList} from '../../components/index';

class OrganizationProfilesEditForm extends React.Component {
  componentDidMount() {
    this.props.updateEditOrganizationProfiles(
      this.props.profilesTabSitesInOrganization,
      this.props.profilesTabSitesNotInOrganization,
    );
  }

  moveToInOrganization = (indices) => {
    const profilesInOrganization = this.props.profilesInOrganization;
    const profilesNotInOrganization = this.props.profilesNotInOrganization;
    const profileLists = this.moveItemsBetweenLists(
      profilesNotInOrganization,
      profilesInOrganization,
      indices,
    );
    this.props.updateEditOrganizationProfiles(profileLists.destination, profileLists.source);
  };

  moveToNotInOrganization = (indices) => {
    const profilesInOrganization = this.props.profilesInOrganization;
    const profilesNotInOrganization = this.props.profilesNotInOrganization;
    const profileLists = this.moveItemsBetweenLists(
      profilesInOrganization,
      profilesNotInOrganization,
      indices,
    );
    this.props.updateEditOrganizationProfiles(profileLists.source, profileLists.destination);
  };

  moveItemsBetweenLists = (source, destination, indices) => {
    const newSource = [...source];
    const itemsToMove = _pullAt(newSource, indices);
    const newDestination = insertIntoSortedArray(destination, itemsToMove, 'ranking');
    return {
      source: newSource,
      destination: newDestination,
    };
  };

  render() {
    const {
      isEditMode,
      profilesInOrganization,
      profilesNotInOrganization,
    } = this.props;

    return (
      <SelectableEntityVerticalPairedList
          handleMoveFromLeftToRightList={this.moveToInOrganization}
          handleMoveFromRightToLeftList={this.moveToNotInOrganization}
          isEditMode={isEditMode}
          leftList={profilesNotInOrganization}
          rightList={profilesInOrganization}
          titleLeft={I18n.t('organizations.tabs.profiles.notInOrganizationLabel')}
          titleRight={I18n.t('organizations.tabs.profiles.inOrganizationLabel')}
      />

    );
  }
}

OrganizationProfilesEditForm.propTypes = {
  isEditMode: PropTypes.bool.isRequired,
  profilesNotInOrganization: PropTypes.array.isRequired,
  profilesInOrganization: PropTypes.array.isRequired,
  updateEditOrganizationProfiles: PropTypes.func.isRequired,
  profilesTabSitesInOrganization: PropTypes.func.isRequired,
  profilesTabSitesNotInOrganization: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  profilesTabSitesInOrganization: state.organizationProfilesTabReducer.profilesInOrganization,
  profilesTabSitesNotInOrganization: state.organizationProfilesTabReducer.profilesNotInOrganization,
  isEditMode: state.organizationProfilesTabReducer.isEditMode,
  profilesInOrganization: state.organizationProfilesEditFormReducer.profilesInOrganization,
  profilesNotInOrganization: state.organizationProfilesEditFormReducer.profilesNotInOrganization,
});

const mapDispatchToProps = (dispatch) => ({
  updateEditOrganizationProfiles: bindActionCreators(updateEditOrganizationProfiles, dispatch),
});

export default
withRouter(connect(mapStateToProps, mapDispatchToProps)(OrganizationProfilesEditForm));
