import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {I18n} from 'react-redux-i18n';
import {v4 as uuid} from 'uuid';
import _isEqual from 'lodash/isEqual';
import _get from 'lodash/get';
import _findIndex from 'lodash/findIndex';
import _find from 'lodash/find';
import _isEmpty from 'lodash/isEmpty';
import _isNull from 'lodash/isNull';
import _isNil from 'lodash/isNil';
import _cloneDeep from 'lodash/cloneDeep';

import {withStyles} from '@material-ui/core/styles';

import {CustomInput} from '../../material-dashboard-pro-react/components/index';

import {
  setValidationResultProfileInfo,
  updateProfileInfo,
  setProfileFromTemplate,
  setSelectedProfileTemplateIndex,
  resetSelectedProfileTemplate,
} from '../ClientProfileEditForm/action';
import {copyProfile} from '../ClientsConfigurer/action';

import {
  isNotEmpty,
  validate,
} from '../../utils/validators';

import {STATES_ENTITY} from '../../constants';
import {TABS as clientTabs} from '../../app-common/Clients/constants';
import {ButtonExpandLessMore, Dropdown, RenderOrEmpty, Switch} from '../../components';

import style from './style';

class ClientProfileSettingsEditForm extends Component {
  static initialLocalState = {
    isShowAdvancedSettings: false,
    validation: {
      name: true,
      description: true,
    },
  };

  static shouldResetFormData(prevProps, props) {
    return !_isEqual(prevProps.isEditMode, props.isEditMode)
      && _isEqual(props.profileState, STATES_ENTITY.EDITING_CANCELED);
  }

  static shouldResetPolicies(prevProps, props) {
    return !_isEqual(prevProps.policies, props.policies)
      || !_isEqual(prevProps.editableProfileInfo.id, props.editableProfileInfo.id);
  }

  constructor(props) {
    super(props);
    this.state = _cloneDeep(ClientProfileSettingsEditForm.initialLocalState);
  }

  componentDidMount() {
    this.resetPolicies();
  }

  shouldComponentUpdate(nextProps, nextState) {
    return !_isEqual(this.props.profile, nextProps.profile)
      || !_isEqual(this.props.isEditMode, nextProps.isEditMode)
      || !_isEqual(this.props.editableProfileInfo, nextProps.editableProfileInfo)
      || !_isEqual(this.props.policies, nextProps.policies)
      || !_isEqual(this.state.isShowAdvancedSettings, nextState.isShowAdvancedSettings)
      || !_isEqual(this.props.selectedProfileTemplateIndex, nextProps.selectedProfileTemplateIndex)
      || !_isEqual(this.props.blockPages, nextProps.blockPages);
  }

  componentDidUpdate(prevProps) {
    if (ClientProfileSettingsEditForm.shouldResetFormData(prevProps, this.props)) {
      this.resetValidation();
      this.props.resetSelectedProfileTemplate();
    }
    if (ClientProfileSettingsEditForm.shouldResetPolicies(prevProps, this.props)) {
      this.resetPolicies();
    }
  }

  getSelectedBlockPageIndex = () => {
    const {
      editableProfileInfo,
      blockPages,
    } = this.props;

    if (_isEqual('', editableProfileInfo.blockPageId)) {
      const index = _findIndex(
        blockPages,
        (item) => _isEqual(item.default, true),
      );

      this.props.updateProfileInfo(
        {
          ...editableProfileInfo,
          blockPageName: blockPages[index].name,
          blockPageId: blockPages[index].id,
        },
        true,
      );

      return index;
    }
    return _findIndex(
      blockPages,
      (item) => _isEqual(item.id, editableProfileInfo.blockPageId),
    );
  };

  getProfileTemplateDropdownOptions = () => this.props.profiles.map((profile) => profile.name);

  getPolicyOptions = () => this.props.policies.map((policy) => policy.name);

  getPolicyOptionsWithProfileDefault = () => [
    I18n.t(`clients.${clientTabs.CONFIGURE}.editProfileForm.profileDefault`),
    ...this.props.policies.map((policy) => policy.name),
  ];

  getPolicyOptionsWithSiteAndProfileDefault = () => [
    I18n.t(`clients.${clientTabs.CONFIGURE}.editProfileForm.profileDefault`),
    I18n.t(`clients.${clientTabs.CONFIGURE}.editProfileForm.siteDefault`),
    ...this.props.policies.map((policy) => policy.name),
  ];

  getSelectedDefaultPolicyIndex = () => {
    const {editableProfileInfo, policies} = this.props;

    return Math.max(0, _findIndex(
      policies,
      (item) => _isEqual(item.id, editableProfileInfo.defaultPolicyId),
    ));
  };

  getSelectedOnsitePolicyIndex = () => {
    const {editableProfileInfo, policies} = this.props;
    const policyId = editableProfileInfo.onsitePolicyId;

    if (editableProfileInfo.onsitePolicySiteDefault) {
      return 1;
    }
    if (editableProfileInfo.onsitePolicyProfileDefault
      || _isNull(policyId)
      || _isEqual('', policyId)
    ) {
      return 0;
    }
    return _findIndex(
      policies,
      (item) => _isEqual(item.id, policyId),
    ) + 2;
  };

  getSelectedOffsitePolicyIndex = () => {
    const {editableProfileInfo, policies} = this.props;
    const policyId = editableProfileInfo.offsitePolicyId;

    if (editableProfileInfo.offsitePolicyProfileDefault
      || _isNull(policyId)
      || _isEqual('', policyId)
    ) {
      return 0;
    }
    return _findIndex(
      policies,
      (item) => _isEqual(item.id, policyId),
    ) + 1;
  };

  handleChangeInputInfo = (e) => {
    const {editableProfileInfo} = this.props;
    const validationResult = this.validate({
      ...this.props.editableProfileInfo,
      [e.target.name]: e.target.value,
    });
    this.props.updateProfileInfo(
      {
        ...editableProfileInfo,
        [e.target.name]: e.target.value,
      },
      validationResult.isValid,
    );
    this.setState({
      validation: validationResult.validationState,
    });
  };

  handleChangeHasDynamicPoliciesSwitch = (e) => {
    const {editableProfileInfo} = this.props;
    this.props.updateProfileInfo(
      {
        ...editableProfileInfo,
        hasDynamicPolicies: e.target.checked,
      },
      true,
    );
  };

  handleChangeDefaultPolicy = (e) => {
    const {editableProfileInfo, policies} = this.props;
    const index = e.target.value;

    this.props.updateProfileInfo(
      {
        ...editableProfileInfo,
        defaultPolicyName: policies[index].name,
        defaultPolicyId: policies[index].id,
      },
      true,
    );
  };

  handleChangeOnsitePolicy = (e) => {
    const {
      editableProfileInfo,
      policies,
    } = this.props;
    const index = e.target.value;
    if (index === 0) {
      this.props.updateProfileInfo(
        {
          ...editableProfileInfo,
          onsitePolicyProfileDefault: true,
          onsitePolicySiteDefault: false,
          // eslint-disable-next-line max-len
          onsitePolicyName: I18n.t(`clients.${clientTabs.CONFIGURE}.editProfileForm.profileDefault`),
          onsitePolicyId: null,
        },
        true,
      );
    } else if (index === 1) {
      this.props.updateProfileInfo(
        {
          ...editableProfileInfo,
          onsitePolicyProfileDefault: false,
          onsitePolicySiteDefault: true,
          onsitePolicyName: I18n.t(`clients.${clientTabs.CONFIGURE}.editProfileForm.siteDefault`),
          onsitePolicyId: null,
        },
        true,
      );
    } else {
      this.props.updateProfileInfo(
        {
          ...editableProfileInfo,
          onsitePolicyProfileDefault: false,
          onsitePolicySiteDefault: false,
          onsitePolicyName: policies[index - 2].name,
          onsitePolicyId: policies[index - 2].id,
        },
        true,
      );
    }
  };

  handleChangeOffsitePolicy = (e) => {
    const {
      editableProfileInfo,
      policies,
    } = this.props;
    const index = e.target.value;
    if (index === 0) {
      this.props.updateProfileInfo(
        {
          ...editableProfileInfo,
          offsitePolicyProfileDefault: true,
          // eslint-disable-next-line max-len
          offsitePolicyName: I18n.t(`clients.${clientTabs.CONFIGURE}.editProfileForm.profileDefault`),
          offsitePolicyId: null,
        },
        true,
      );
    } else {
      this.props.updateProfileInfo(
        {
          ...editableProfileInfo,
          offsitePolicyProfileDefault: false,
          offsitePolicyName: policies[index - 1].name,
          offsitePolicyId: policies[index - 1].id,
        },
        true,
      );
    }
  };

  handleChangeProfileTemplate = (e) => {
    const templateIndex = e.target.value;
    const {
      editableProfileInfo,
      profiles,
      // eslint-disable-next-line no-shadow
      copyProfile,
      // eslint-disable-next-line no-shadow
      setProfileFromTemplate,
      // eslint-disable-next-line no-shadow
      setSelectedProfileTemplateIndex,
    } = this.props;
    copyProfile(
      profiles[templateIndex].id,
      editableProfileInfo,
    ).then(() => {
      setProfileFromTemplate(this.props.profileTemplate);
      setSelectedProfileTemplateIndex(templateIndex);
    });
  };

  resetPolicies = () => {
    const {policies, profile} = this.props;

    const defaultPolicy = _find(
      policies || [],
      (item) => _isEqual(item.id, profile.defaultPolicyId),
    ) || {};
    const onsitePolicy = _find(
      policies || [],
      (item) => _isEqual(item.id, profile.onsitePolicyId),
    ) || {};
    const offsitePolicy = _find(
      policies || [],
      (item) => _isEqual(item.id, profile.offsitePolicyId),
    ) || {};

    let onsitePolicyProfileDefault = profile.onsitePolicyProfileDefault;
    let onsitePolicySiteDefault = profile.onsitePolicySiteDefault;
    let onsitePolicyName = onsitePolicy.name;
    if (_isNil(onsitePolicy.id)) {
      if (profile.onsitePolicySiteDefault) {
        onsitePolicyProfileDefault = false;
        onsitePolicySiteDefault = true;
        onsitePolicyName = I18n.t(`clients.${clientTabs.CONFIGURE}.editProfileForm.siteDefault`);
      } else {
        onsitePolicyProfileDefault = true;
        onsitePolicySiteDefault = false;
        onsitePolicyName = I18n.t(`clients.${clientTabs.CONFIGURE}.editProfileForm.profileDefault`);
      }
    }
    this.props.updateProfileInfo(
      {
        ...profile,
        defaultPolicyName: defaultPolicy.name || '',
        defaultPolicyId: _get(defaultPolicy, 'id', _get(policies[0] || {}, 'id', null)),
        onsitePolicyId: onsitePolicy.id,
        onsitePolicyProfileDefault,
        onsitePolicySiteDefault,
        onsitePolicyName,
        offsitePolicyId: offsitePolicy.id,
        offsitePolicyProfileDefault: _isNil(offsitePolicy.id),
        offsitePolicyName: offsitePolicy.name
          || I18n.t(`clients.${clientTabs.CONFIGURE}.editProfileForm.profileDefault`),
      },
      true,
    );
  };

  resetValidation = () => {
    this.props.setValidationResultProfileInfo(true);
    this.setState(ClientProfileSettingsEditForm.initialLocalState);
  };

  toggleAdvancedSettings = () => {
    const {isShowAdvancedSettings} = this.state;
    this.setState({isShowAdvancedSettings: !isShowAdvancedSettings});
  };

  validate = (data) => validate(
    [
      {
        name: 'name',
        validationRules: [isNotEmpty],
      },
      {
        name: 'description',
        validationRules: [],
      },
    ],
    data,
    {
      name: true,
      description: true,
    },
  );

  handleChangeProfileBlockPage = (e) => {
    const {
      editableProfileInfo,
      blockPages,
    } = this.props;
    this.props.updateProfileInfo(
      {
        ...editableProfileInfo,
        blockPageName: blockPages[e.target.value].name,
        blockPageId: blockPages[e.target.value].id,
      },
      true,
    );
  };

  render() {
    const {
      classes,
      editableProfileInfo,
      isEditMode,
      policies,
      selectedProfileTemplateIndex,
      blockPages,
    } = this.props;

    const {validation, isShowAdvancedSettings} = this.state;

    const propsInput = {
      name: {
        disabled: !isEditMode,
        value: editableProfileInfo.name,
        name: 'name',
        onChange: this.handleChangeInputInfo,
        type: 'text',
      },
      description: {
        disabled: !isEditMode,
        value: editableProfileInfo.description,
        name: 'description',
        onChange: this.handleChangeInputInfo,
        type: 'text',
      },
    };
    const propsFormControl = {
      fullWidth: true,
    };
    const isPoliciesDisabled = !isEditMode || _isEmpty(policies);

    return (
      <div className={classes.settings}>
        <RenderOrEmpty isShow={isEditMode}>
          <div className={classes.settings__templateSection}>
            <Dropdown
              customSelectClass="dropdown__select_clientProfile"
              disabled={!isEditMode}
              fullWidth={true}
              label={I18n.t(`clients.${clientTabs.CONFIGURE}.editProfileForm.copyProfile`)}
              onChangeValue={this.handleChangeProfileTemplate}
              options={this.getProfileTemplateDropdownOptions()}
              selectedItemIndex={selectedProfileTemplateIndex}
            />
          </div>
        </RenderOrEmpty>
        <div className={classes.settings__baseSection}>
          <div className={classes.baseSection__item}>
            <CustomInput
              errorText={validation.name
                ? undefined
                : I18n.t(`clients.${clientTabs.CONFIGURE}.errorMessages.profileNameNotValid`)}
              error={!validation.name}
              // eslint-disable-next-line max-len
              labelText={I18n.t(`clients.${clientTabs.CONFIGURE}.editProfileForm.labels.profileName`)}
              id={uuid()}
              formControlProps={propsFormControl}
              inputProps={propsInput.name}
            />
          </div>
          <div className={classes.baseSection__item}>
            <Dropdown
              customSelectClass="dropdown__select_policySettingsTab"
              disabled={isPoliciesDisabled}
              dropdownName="defaultPolicy"
              fullWidth={true}
              label={I18n.t(`clients.${clientTabs.CONFIGURE}.editProfileForm.labels.defaultPolicy`)}
              onChangeValue={this.handleChangeDefaultPolicy}
              options={this.getPolicyOptions()}
              selectedItemIndex={this.getSelectedDefaultPolicyIndex()}
            />
          </div>
          <div className={classes.baseSection__item}>
            <CustomInput
              // eslint-disable-next-line max-len
              labelText={I18n.t(`clients.${clientTabs.CONFIGURE}.editProfileForm.labels.description`)}
              id={uuid()}
              formControlProps={propsFormControl}
              inputProps={propsInput.description}
            />
          </div>
          <div className={classes.baseSection__item}>
            <Dropdown
              disabled={!isEditMode}
              dropdownName="blockPage"
              fullWidth={true}
              // eslint-disable-next-line max-len
              label={I18n.t(`clients.${clientTabs.CONFIGURE}.editProfileForm.labels.defaultBlockPage`)}
              onChangeValue={this.handleChangeProfileBlockPage}
              options={blockPages.sort((a) => (!a.default ? 1 : -1)).map((item) => item.name)}
              selectedItemIndex={this.getSelectedBlockPageIndex()}
            />
          </div>
        </div>
        <div className={classes.settings__advancedSection}>
          <ButtonExpandLessMore
            customClasses="clientProfileSettingsTab__buttonShowHideAdvancedMode"
            isExpandLess={isShowAdvancedSettings}
            onClick={this.toggleAdvancedSettings}
            text={I18n.t(`clients.${clientTabs.CONFIGURE}.editProfileForm.advancedSectionTitle`)}
          />
          <RenderOrEmpty isShow={isShowAdvancedSettings}>
            <Switch
              name="hasDynamicPolicies"
              // eslint-disable-next-line max-len
              label={I18n.t(`clients.${clientTabs.CONFIGURE}.editProfileForm.labels.dynamicPolicies`)}
              disabled={!isEditMode}
              isChecked={editableProfileInfo.hasDynamicPolicies}
              onChange={this.handleChangeHasDynamicPoliciesSwitch}
              labelFormClassName="formControl_oneLineReversedLeftAligned"
              labelClassName="switch__label_oneLine"
            />
            <RenderOrEmpty isShow={editableProfileInfo.hasDynamicPolicies}>
              <div className={classes.settings__dynamicPoliciesSection}>
                <div className={classes.advancedSection__item}>
                  <Dropdown
                    customSelectClass="dropdown__select_policySettingsTab"
                    disabled={isPoliciesDisabled}
                    dropdownName="onsitePolicy"
                    fullWidth={true}
                    // eslint-disable-next-line max-len
                    label={I18n.t(`clients.${clientTabs.CONFIGURE}.editProfileForm.labels.onsitePolicy`)}
                    onChangeValue={this.handleChangeOnsitePolicy}
                    options={this.getPolicyOptionsWithSiteAndProfileDefault()}
                    selectedItemIndex={this.getSelectedOnsitePolicyIndex()}
                  />
                </div>
                <div className={classes.advancedSection__item}>
                  <Dropdown
                    customSelectClass="dropdown__select_policySettingsTab"
                    disabled={isPoliciesDisabled}
                    dropdownName="defaultPolicy"
                    fullWidth={true}
                    // eslint-disable-next-line max-len
                    label={I18n.t(`clients.${clientTabs.CONFIGURE}.editProfileForm.labels.offsitePolicy`)}
                    onChangeValue={this.handleChangeOffsitePolicy}
                    options={this.getPolicyOptionsWithProfileDefault()}
                    selectedItemIndex={this.getSelectedOffsitePolicyIndex()}
                  />
                </div>
              </div>
            </RenderOrEmpty>
          </RenderOrEmpty>
        </div>
      </div>
    );
  }
}

ClientProfileSettingsEditForm.propTypes = {
  profile: PropTypes.object.isRequired,
  profileTemplate: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  editableProfileInfo: PropTypes.object.isRequired,
  isEditMode: PropTypes.bool.isRequired,
  policies: PropTypes.array.isRequired,
  profiles: PropTypes.array.isRequired,
  blockPages: PropTypes.array.isRequired,
  selectedProfileTemplateIndex: PropTypes.number,

  copyProfile: PropTypes.func.isRequired,
  setValidationResultProfileInfo: PropTypes.func.isRequired,
  updateProfileInfo: PropTypes.func.isRequired,
  setSelectedProfileTemplateIndex: PropTypes.func.isRequired,
  resetSelectedProfileTemplate: PropTypes.func.isRequired,
  setProfileFromTemplate: PropTypes.func.isRequired,
};

ClientProfileSettingsEditForm.defaultProps = {
  selectedProfileTemplateIndex: null,
};

const mapStateToProps = (state) => ({
  profile: state.clientsConfigurerReducer.selectedProfile,
  profileTemplate: state.clientsConfigurerReducer.profileToCopy,
  profileState: state.clientsConfigurerReducer.profileState,
  isEditMode: state.clientsConfigurerReducer.isEditMode,
  profiles: state.clientsConfigurerReducer.profiles,
  policies: state.clientsConfigurerReducer.policies,
  blockPages: state.blockPageReducer.blockPages,

  // eslint-disable-next-line max-len
  selectedProfileTemplateIndex: state.clientProfileEditFormReducer.settings.selectedProfileTemplateIndex,
  editableProfileInfo: state.clientProfileEditFormReducer.settings.editableProfileInfo,
  settings: state.clientProfileEditFormReducer.settings,
});

const mapDispatchToProps = (dispatch) => ({
  setValidationResultProfileInfo: bindActionCreators(setValidationResultProfileInfo, dispatch),
  updateProfileInfo: bindActionCreators(updateProfileInfo, dispatch),
  setSelectedProfileTemplateIndex: bindActionCreators(setSelectedProfileTemplateIndex, dispatch),
  copyProfile: bindActionCreators(copyProfile, dispatch),
  setProfileFromTemplate: bindActionCreators(setProfileFromTemplate, dispatch),
  resetSelectedProfileTemplate: bindActionCreators(resetSelectedProfileTemplate, dispatch),
});

const ClientProfileSettingsEditFormWithStyles = withStyles(style)(ClientProfileSettingsEditForm);
export default
connect(mapStateToProps, mapDispatchToProps)(ClientProfileSettingsEditFormWithStyles);
