import React from 'react';
import PropTypes from 'prop-types';
import {
  PieChart,
  Pie,
  Tooltip,
  Cell,
  ResponsiveContainer,
} from 'recharts';
import {v4 as uuid} from 'uuid';

import {
  CardWithHeader,
  CustomTable,
  RenderOrEmpty,
} from '../index';
import {LoadingLayout} from '../../layouts-common';

import {
  yellowOtherColor,
  darkBlueColor,
  lightBlueColor,
  brightRedColor,
  darkerGreenColor,
} from '../../styles/style';

function TopCategoriesCard(props) {
  const {
    classes,
    chartData,
    headers,
    tableData,
    textWhenDataNotAvailable,
    title,
    titleColor,
    widgetKey,
  } = props;

  const renderLabel = (_props) => {
    const RADIAN = Math.PI / 180;
    const {
      cx,
      cy,
      midAngle,
      outerRadius,
      percent,
    } = _props;
    const x = cx + (outerRadius * 1.1 * Math.cos(-midAngle * RADIAN));
    const y = cy + (outerRadius * 1.1 * Math.sin(-midAngle * RADIAN));
    const textAnchor = x > cx ? 'start' : 'end';
    return (
      <g>
        <text
          x={x}
          y={y}
          textAnchor={textAnchor}
          fill="#333"
        >
          {`${(percent * 100).toFixed(0)}%`}
        </text>
      </g>
    );
  };

  const getPieCells = () => {
    const COLORS = [
      brightRedColor,
      darkerGreenColor,
      yellowOtherColor,
      darkBlueColor,
      lightBlueColor,
    ];
    return chartData.map((entry, index) => (
      <Cell
        fill={COLORS[index % COLORS.length]}
        key={uuid()}
      />
    ));
  };

  const thereIsNotData = tableData.length === 0;
  return (
    <CardWithHeader
        cardTitle={title}
        cardTitleColor={titleColor}
        content={(
          <LoadingLayout widgetKey={widgetKey}>
            <div className={classes.topCategories}>
              <RenderOrEmpty isShow={thereIsNotData}>
                <h3 className={classes.topBlock__thereIsNotData}>
                  {textWhenDataNotAvailable}
                </h3>
              </RenderOrEmpty>
              <RenderOrEmpty isShow={!thereIsNotData}>
                <div className={classes.topCategories__graph}>
                  <ResponsiveContainer width="100%" height={300}>
                    <PieChart>
                      <Pie
                        isAnimationActive={false}
                        data={chartData}
                        fill="#8884d8"
                        label={renderLabel}
                        labelLine={false}
                        dataKey="value"
                      >
                        {getPieCells()}
                      </Pie>
                      <Tooltip label="percent" />
                    </PieChart>
                  </ResponsiveContainer>
                </div>
                <div className={classes.topCategories__table}>
                  <CustomTable
                    tableHead={headers}
                    tableData={tableData}
                  />
                </div>
              </RenderOrEmpty>
            </div>
          </LoadingLayout>
        )}
    />
  );
}

TopCategoriesCard.propTypes = {
  classes: PropTypes.object.isRequired,
  chartData: PropTypes.array.isRequired,
  headers: PropTypes.array.isRequired,
  tableData: PropTypes.array.isRequired,
  textWhenDataNotAvailable: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  titleColor: PropTypes.string.isRequired,
  widgetKey: PropTypes.string.isRequired,
};

TopCategoriesCard.defaultProps = {};

export default TopCategoriesCard;
