import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {I18n} from 'react-redux-i18n';
import _isEqual from 'lodash/isEqual';
import _includes from 'lodash/includes';

import {NavPills} from '../../material-dashboard-pro-react/components/index';

import {
  PolicyCategoriesTab,
  PolicySettingsTab,
} from '../../components/index';

import {
  copyPolicy,
  getPolicyFormValues,
  resetPolicyFormValues,
  resetSelectedPolicyTemplateIndex,
  setSelectedPolicyTemplateIndex,
  updateAdvancedSettings,
  updateBaseSetting,
  updateCategoryStatus,
} from './action';

import {
  STATES_ENTITY,
  TAGS,
} from '../../constants';

class PolicyEditForm extends Component {
  static shouldUpdatePolicySettings(prevProps, props) {
    return !_isEqual(prevProps.policy, props.policy);
  }

  static shouldResetPolicySettings(prevProps, props) {
    return !_isEqual(prevProps.isEditMode, props.isEditMode)
    && _isEqual(props.policyState, STATES_ENTITY.EDITING_CANCELED);
  }

  componentDidMount() {
    this.updateFormData(this.props);
  }

  componentDidUpdate(prevProps) {
    if (PolicyEditForm.shouldUpdatePolicySettings(prevProps, this.props)
    || PolicyEditForm.shouldResetPolicySettings(prevProps, this.props)) {
      this.updateFormData(this.props);
    }
  }

  componentWillUnmount() {
    this.props.resetPolicyFormValues();
  }

  getButtonsColor = () => {
    const {isEditMode} = this.props;
    if (isEditMode) {
      return 'edit';
    }
    return 'secondary';
  };

  updateFormData = (props) => {
    this.props.getPolicyFormValues(props.policy);
    this.props.resetSelectedPolicyTemplateIndex();
  };

  handleChangeCategoryStatus = (e) => {
    this.props.updateCategoryStatus(e);
  };

  handleChangePolicyTemplate = (templateIndex) => {
    const {
      policy,
      policies,
    } = this.props;
    this.props.copyPolicy(policies[templateIndex], policy);
    this.props.setSelectedPolicyTemplateIndex(templateIndex);
  };

  handleChangeBaseSettings = (settingName, settingObject) => {
    this.props.updateBaseSetting(settingName, settingObject);
  };

  handleChangeAdvancedSettings = (settingName, settingObject) => {
    this.props.updateAdvancedSettings(settingName, settingObject);
  };

  render() {
    const {
      advancedSettingsObject,
      baseSettingsObject,
      applicationsTabFormScheme,
      categoriesObject,
      contentTabFormScheme,
      isEditMode,
      policy,
      policies,
      threatsTabFormScheme,
      selectedPolicyTemplateIndex,
    } = this.props;
    const isReadOnlyPolicy = _includes(policy.tags, TAGS.READ_ONLY_POLICY);
    return (
      <NavPills
        key={policy.id}
        color={this.getButtonsColor()}
        tabs={[
          {
            tabButton: I18n.t('policyPage.settingsTab.tabName'),
            tabContent: (
              <PolicySettingsTab
                advancedSettings={advancedSettingsObject}
                baseSettings={baseSettingsObject}
                isEditMode={isEditMode}
                isReadOnly={isReadOnlyPolicy}
                policies={policies}
                selectedPolicyTemplateIndex={selectedPolicyTemplateIndex}
                onChangeAdvancedSettings={this.handleChangeAdvancedSettings}
                onChangeBaseSettings={this.handleChangeBaseSettings}
                onChangePolicyTemplate={this.handleChangePolicyTemplate}
              />
            ),
          },
          {
            tabButton: I18n.t('policyPage.threatsTab.tabName'),
            tabContent: (
              <PolicyCategoriesTab
                isEditMode={isEditMode}
                categories={categoriesObject}
                formScheme={threatsTabFormScheme}
                handleChangeCategory={this.handleChangeCategoryStatus}
              />
            ),
          },
          {
            tabButton: I18n.t('policyPage.contentTab.tabName'),
            tabContent: (
              <PolicyCategoriesTab
                categories={categoriesObject}
                formScheme={contentTabFormScheme}
                handleChangeCategory={this.handleChangeCategoryStatus}
                isEditMode={isEditMode}
              />
            ),
          },
          {
            tabButton: I18n.t('policyPage.applicationsTab.tabName'),
            tabContent: (
              <PolicyCategoriesTab
                categories={categoriesObject}
                isEditMode={isEditMode}
                formScheme={applicationsTabFormScheme}
                handleChangeCategory={this.handleChangeCategoryStatus}
              />
            ),
          },
        ]}
      />
    );
  }
}

PolicyEditForm.propTypes = {
  advancedSettingsObject: PropTypes.object.isRequired,
  applicationsTabFormScheme: PropTypes.array.isRequired,
  baseSettingsObject: PropTypes.object.isRequired,
  categoriesObject: PropTypes.object.isRequired,
  contentTabFormScheme: PropTypes.array.isRequired,
  isEditMode: PropTypes.bool.isRequired,
  policies: PropTypes.array.isRequired,
  policy: PropTypes.object.isRequired,
  threatsTabFormScheme: PropTypes.array.isRequired,
  selectedPolicyTemplateIndex: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]).isRequired,

  copyPolicy: PropTypes.func.isRequired,
  getPolicyFormValues: PropTypes.func.isRequired,
  setSelectedPolicyTemplateIndex: PropTypes.func.isRequired,
  resetPolicyFormValues: PropTypes.func.isRequired,
  resetSelectedPolicyTemplateIndex: PropTypes.func.isRequired,
  updateAdvancedSettings: PropTypes.func.isRequired,
  updateBaseSetting: PropTypes.func.isRequired,
  updateCategoryStatus: PropTypes.func.isRequired,
};

PolicyEditForm.defaultProps = {};

const mapStateToProps = (state) => ({
  policies: state.policyReducer.policies,
  policyState: state.policyReducer.policyState,
  isEditMode: state.policyReducer.isEditMode,
  policy: state.policyReducer.selectedPolicy,

  advancedSettingsObject: state.policyEditFormReducer.advancedSettingsObject,
  applicationsTabFormScheme: state.policyEditFormReducer.applicationsTabFormScheme,
  baseSettingsObject: state.policyEditFormReducer.baseSettingsObject,
  categoriesObject: state.policyEditFormReducer.categoriesObject,
  contentTabFormScheme: state.policyEditFormReducer.contentTabFormScheme,
  policyTemplateToCreate: state.policyEditFormReducer.policyTemplateToCreate,
  threatsTabFormScheme: state.policyEditFormReducer.threatsTabFormScheme,
  selectedPolicyTemplateIndex: state.policyEditFormReducer.selectedPolicyTemplateIndex,
});

const mapDispatchToProps = (dispatch) => ({
  copyPolicy: bindActionCreators(copyPolicy, dispatch),
  getPolicyFormValues: bindActionCreators(getPolicyFormValues, dispatch),
  resetPolicyFormValues: bindActionCreators(resetPolicyFormValues, dispatch),
  resetSelectedPolicyTemplateIndex: bindActionCreators(resetSelectedPolicyTemplateIndex, dispatch),
  setSelectedPolicyTemplateIndex: bindActionCreators(setSelectedPolicyTemplateIndex, dispatch),
  updateAdvancedSettings: bindActionCreators(updateAdvancedSettings, dispatch),
  updateBaseSetting: bindActionCreators(updateBaseSetting, dispatch),
  updateCategoryStatus: bindActionCreators(updateCategoryStatus, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(PolicyEditForm);
