import _isEqual from 'lodash/isEqual';
import _cloneDeep from 'lodash/cloneDeep';
import _findIndex from 'lodash/findIndex';
import _isUndefined from 'lodash/isUndefined';
import _isNil from 'lodash/isNil';
import _sortBy from 'lodash/sortBy';

import {
  POLICY_FORM_COMPONENTS_TYPES,
  POLICY_FORM_ITEMS,
} from '../../constants';

export function getPolicyCopy(newPolicy, currentPolicy) {
  const baseSettings = {
    name: {
      name: 'Policy name',
      type: POLICY_FORM_COMPONENTS_TYPES.INPUT,
      value: currentPolicy.name,
    },
    description: {
      name: 'Policy description',
      type: POLICY_FORM_COMPONENTS_TYPES.INPUT,
      value: currentPolicy.description,
    },
  };

  return {
    baseSettingsObject: buildObjectFromArray(newPolicy.baseSettings, baseSettings),
    advancedSettingsObject: buildObjectFromArray(newPolicy.advancedSettings, {}),
    categoriesObject: buildObjectFromArray(newPolicy.categories, {}),
    strategyStatusesObject: newPolicy.strategyStatuses,
    tldFilteringSettingsObject: newPolicy.tldFilteringSettings,
  };
}

export function getTabScheme(tabData, groupName) {
  const itemsWithDepthMoreZero = tabData.filter((item) => item.depth > 0);
  if (itemsWithDepthMoreZero.length > 0) {
    const parents = _sortBy(
      tabData.filter((item) => _isEqual(item.depth, 0)),
      (_parent) => _parent.group.toLowerCase(),
    );
    return parents.map((parentItem) => {
      const children = tabData.filter((item) => _isEqual(item.parent, parentItem.group));
      return {
        groupName: parentItem.group,
        children: _sortBy(
          children.map((item) => item.group),
          (child) => child.toLowerCase(),
        ),
      };
    });
  }
  return [{
    groupName: groupName,
    children: _sortBy(
      tabData.map((item) => item.group),
      (child) => child.toLowerCase(),
    ),
  }];
}

export function getSettingsAsObjects(policy) {
  // TODO: Add localization to name and description
  const baseSettings = {
    name: {
      name: 'Policy name',
      type: POLICY_FORM_COMPONENTS_TYPES.INPUT,
      value: policy.name,
    },
    description: {
      name: 'Policy description',
      type: POLICY_FORM_COMPONENTS_TYPES.INPUT,
      value: policy.description,
    },
  };

  return {
    baseSettingsObject: buildObjectFromArray(policy.baseSettings, baseSettings),
    advancedSettingsObject: buildObjectFromArray(policy.advancedSettings, {}),
    categoriesObject: buildObjectFromArray(policy.categories, {}),
    strategyStatusesObject: policy.strategyStatuses,
    tldFilteringSettingsObject: policy.tldFilteringSettings,
  };
}

function buildObjectFromArray(array, object) {
  if (_isNil(array)) {return object;}
  array.forEach((item) => {
    object[item.name] = {...item};
  });
  return object;
}

export function getUpdatedCategoriesObject(categoriesObject, categoryName, categoryStatus) {
  const cloneCategoriesObject = _cloneDeep(categoriesObject);
  const updatedCategory = cloneCategoriesObject[categoryName];
  if (_isUndefined(updatedCategory)) {
    cloneCategoriesObject[categoryName] = POLICY_FORM_ITEMS.CATEGORY;
    cloneCategoriesObject[categoryName].name = categoryName;
  }
  cloneCategoriesObject[categoryName].value = getCategoryValue(categoryStatus);
  return cloneCategoriesObject;
}

function getCategoryValue(categoryStatus) {
  return _findIndex(
    POLICY_FORM_ITEMS.CATEGORY.values,
    (o) => _isEqual(o.name, categoryStatus),
  );
}

export function getUpdatedSettingsObject(allSettingsObject, settingName, settingObject) {
  const cloneAllSettingsObject = _cloneDeep(allSettingsObject);
  cloneAllSettingsObject[settingName] = settingObject;
  return cloneAllSettingsObject;
}
