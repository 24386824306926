import React from 'react';
import PropTypes from 'prop-types';
import {I18n} from 'react-redux-i18n';

import {EntityCreatingCard} from '../index';
import {PolicyEditForm} from '../../containers/index';

function PolicyCreating(props) {
  const {
    handleClickButtonCancel,
    handleClickButtonSave,
  } = props;

  return (
    <EntityCreatingCard
      title={I18n.t('policyPage.createPolicyTitle')}
      handleClickButtonCancel={handleClickButtonCancel}
      handleClickButtonSave={handleClickButtonSave}
      customCardContentClass="card__content_policy"
      content={(
        <PolicyEditForm />
      )}
    />
  );
}

PolicyCreating.propTypes = {
  handleClickButtonCancel: PropTypes.func.isRequired,
  handleClickButtonSave: PropTypes.func.isRequired,
};

export default PolicyCreating;
