import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {I18n} from 'react-redux-i18n';
import _findIndex from 'lodash/findIndex';
import classNames from 'classnames';

import {withStyles} from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';

import {CustomButton, CustomInput} from '../../material-dashboard-pro-react/components';
import {
  setSearchOption,
  setDomainType,
  setInsightsComponents,
} from '../../app-common/Insights/action';
import {
  ROWS_OPTIONS,
  DOMAIN_TYPES,
  TABS,
  HIDE_RESULT_SELECTION,
  HIDE_DOMAIN_TYPE_SELECTION,
  HIDE_ROWS_SELECTION,
  HIDE_NAME_SEARCH,
  NAME_SEARCH_PROPS,
  COMPONENTS,
} from '../../app-common/Insights/constants';
import {getAreFiltersDisabled, getTabSearchOptions} from '../../app-common/Insights/selectors';
import {Dropdown, PillsWithLabel, InsightsFiltersTooltip} from '../../components';
import {ENTER_KEY_CODE, INSIGHTS_DECISIONS, INSIGHTS_PERIODS} from '../../constants';
import {Refresh, Close} from '../../icons';

import style from './style';
import './style.css';

class InsightsControls extends React.Component {
  constructor(props) {
    super(props);
    const {period} = props.searchOptions;
    const selectedPeriodOption = _findIndex(Object.values(INSIGHTS_PERIODS), (p) => p === period);
    this.state = {
      selectedPeriodOption,
    };
  }

  onPeriodChange = (e) => {
    const index = e.target.value;
    this.setState({
      selectedPeriodOption: index,
    });
    this.props.setFilter(
      this.props.tab,
      'period',
      Object.values(INSIGHTS_PERIODS)[index],
    );
  };

  onNameSearchKeyPressed = (e) => {
    if (e.charCode === ENTER_KEY_CODE) {
      this.props.onRefresh();
    }
  };

  get rows() {
    const {tab} = this.props;
    if (HIDE_ROWS_SELECTION.includes(tab)) return null;

    const {rows} = this.props.searchOptions;
    const rowsPills = {
      name: 'rows',
      label: I18n.t('insights.searchOptions.rows'),
      options: ROWS_OPTIONS.map((o) => ({
        label: o,
        value: o,
      })),
      defaultValue: rows,
    };
    return (
      <PillsWithLabel
      {...rowsPills}
      selectOption={this.setFilter}
      />
    );
  }

  get result() {
    const {tab} = this.props;
    if (HIDE_RESULT_SELECTION.includes(tab)) return null;

    const {result} = this.props.searchOptions;
    const resultPills = {
      name: 'result',
      label: I18n.t('insights.searchOptions.result'),
      options: [
        {value: null, label: I18n.t('decisions.ALL')},
        ...Object.keys(INSIGHTS_DECISIONS).map((d) => ({
          value: d,
          label: I18n.t(`decisions.${d}`),
        }))],
      defaultValue: result,
    };
    return (
      <PillsWithLabel
        {...resultPills}
        selectOption={this.setFilter}
      />
    );
  }

  get domainType() {
    const {tab} = this.props;
    if (HIDE_DOMAIN_TYPE_SELECTION.includes(tab)) return null;

    const domainType = this.props.domainType;

    const domainTypePills = {
      name: 'domainType',
      label: I18n.t('insights.searchOptions.domainType'),
      options: [
        {
          value: DOMAIN_TYPES.DOMAIN,
          label: I18n.t('domainTypes.DOMAIN'),
        },
        {
          value: DOMAIN_TYPES.FQDN,
          label: I18n.t('domainTypes.FQDN'),
        },
      ],
      defaultValue: domainType,
    };
    return (
      <PillsWithLabel
        {...domainTypePills}
        selectOption={this.changeDomainType}
      />
    );
  }

  get nameSearch() {
    const {tab, searchOptions, domainType} = this.props;
    const nameSearchProps = tab === TABS.DOMAIN
      ? NAME_SEARCH_PROPS[tab][domainType]
      : NAME_SEARCH_PROPS[tab];
    if (HIDE_NAME_SEARCH.includes(tab)) {return null;}
    return (
      <CustomInput
        name={nameSearchProps.name}
        type="text"
        inputProps={{
          value: domainType === DOMAIN_TYPES.DOMAIN
            ? searchOptions.parentDomain
            : searchOptions.domainName,
          disabled: this.props.disabled,
          onChange: (e) => this.setFilter(nameSearchProps.name, e.target.value.toLowerCase()),
          onKeyPress: this.onNameSearchKeyPressed,
          placeholder: `${I18n.t('insights.searchOptions.nameSearch.placeholderPrefix')}: `
            + `${nameSearchProps.viewName}`,
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                onClick={() => this.setFilter(nameSearchProps.name, '')}
              >
                <Close />
              </IconButton>
            </InputAdornment>
          ),
        }}
        formControlProps={{
          className: 'insights-controls__name-search-input-form',
        }}
      />
    );
  }

  get domainTypeToDomainComponent() {
    return {
      [DOMAIN_TYPES.DOMAIN]: COMPONENTS.DOMAINS,
      [DOMAIN_TYPES.FQDN]: COMPONENTS.FQDNS,
    };
  }

  setFilter = (filterName, value) => this.props.setFilter(
    this.props.tab,
    filterName,
    value,
  );

  changeDomainType = (name, value) => {
    this.props.changeDomainType(value);
    this.props.changeInsightsComponents(TABS.DOMAIN, [this.domainTypeToDomainComponent[value]]);
    this.props.onRefresh();
  };

  render() {
    const insightsControlsClassName = classNames(
      'insights-controls',
      {'insights-controls_disabled': this.props.disabled},
    );
    const periodOptions = Object.keys(INSIGHTS_PERIODS)
      .map((period) => I18n.t(`dashboard.filters.${period}`));
    const dropdownClasses = {
      dropdown__menu: this.props.classes.insightsControls__dropdown,
    };

    return (
      <InsightsFiltersTooltip filtersEnabled={!this.props.disabled}>
        <div className={insightsControlsClassName}>
          <div>
            {this.domainType}
            {this.result}
            {this.rows}
            {this.nameSearch}
          </div>
          <div className="insights-controls__period">
            <Dropdown
              classes={dropdownClasses}
              options={periodOptions}
              selectedItemIndex={this.state.selectedPeriodOption}
              customSelectClass="dropdown__select_insights"
              onChangeValue={this.onPeriodChange}
            />
            <CustomButton
                color="secondary"
                customClasses="filtersPanel__button_refresh"
                onClick={this.props.onRefresh}
                size="sm"
            >
              <Refresh />
              {I18n.t('buttons.refresh').toUpperCase()}
            </CustomButton>
          </div>
        </div>
      </InsightsFiltersTooltip>
    );
  }
}

InsightsControls.propTypes = {
  classes: PropTypes.object.isRequired,
  setFilter: PropTypes.func.isRequired,
  changeDomainType: PropTypes.func.isRequired,
  domainType: PropTypes.string.isRequired,
  onRefresh: PropTypes.func.isRequired,
  tab: PropTypes.oneOf(Object.values(TABS)).isRequired,
  searchOptions: PropTypes.shape({
    rows: PropTypes.number,
    result: PropTypes.string,
    period: PropTypes.string,
    domainName: PropTypes.string,
    parentDomain: PropTypes.string,
  }).isRequired,
  disabled: PropTypes.bool.isRequired,
  changeInsightsComponents: PropTypes.func.isRequired,
};

const mapStateToProps = (state, props) => ({
  searchOptions: getTabSearchOptions(props.tab)(state),
  disabled: getAreFiltersDisabled(props.tab)(state),
  domainType: state.insightsReducer.domainType,
});

const mapDispatchToProps = {
  setFilter: setSearchOption,
  changeDomainType: setDomainType,
  changeInsightsComponents: setInsightsComponents,
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(style)(InsightsControls));
