import {I18n} from 'react-redux-i18n';
import _get from 'lodash/get';

import {
  get,
  getWithInterrupt,
  makeUrl,
} from '../../utils/fetcher';

import {types, widgetKeys} from './constants';
import {
  getPolicies,
  resetPolicies,
} from '../Policy/action';
import {
  getCampuses,
} from '../Campus/action';

import {openModalWindow} from '../../scout-dns/layouts/Operator/action';
import getLogsTableData from '../../services/logs/getLogsTableData';

import {
  METHODS,
  MODAL_WINDOW_NOTIFY_TYPE,
} from '../../constants';

export function getLoggingFiltersPanelData(includeOrganizations, deviceAgentAllowed) {
  return (dispatch) => {
    if (includeOrganizations) {dispatch(getOrganizationNames());}
    dispatch(getCampuses());
    dispatch(getPolicies());
    if (deviceAgentAllowed) dispatch(getClientNames());
  };
}

// TODO: add pagination to the request
export function getClientNames() {
  const url = makeUrl(METHODS.GET_CLIENT_NAMES);
  return (dispatch) => {
    const requestInfo = getWithInterrupt(url, dispatch);
    requestInfo.request
      .then((response) => {
        if (response && response.data) {
          dispatch({
            type: types.SET_CLIENT_NAMES,
            data: response.data,
          });
        }
      });
    return requestInfo.request;
  };
}

export function getOrganizationNames() {
  const url = makeUrl(METHODS.GET_ORGANIZATION_NAMES);
  return (dispatch) => {
    const requestInfo = getWithInterrupt(url, dispatch);
    requestInfo.request
      .then((response) => {
        if (response && response.data) {
          dispatch({
            type: types.SET_ORGANIZATION_NAMES_FOR_LOGS,
            data: response.data,
          });
        }
      });
    return requestInfo.request;
  };
}

function getAuditJson(filters, actionType, hideError, widgetKey = widgetKeys.LOG_TABLE) {
  const url = makeUrl(METHODS.GET_AUDIT_JSON, filters);
  return (dispatch, getState) => {
    const requestInfo = getWithInterrupt(url, dispatch, true, widgetKey);
    requestInfo.request
      .then((response) => {
        if (response && response.data) {
          const data = response.data;
          if (data.length === 0) {
            dispatch({
              type: actionType,
              data: [],
            });
            if (!hideError) {
              dispatch(openModalWindow(
                I18n.t('logPage.info.logIsEmpty'),
                MODAL_WINDOW_NOTIFY_TYPE.INFO,
              ));
            }
          } else {
            const maxInspectionDays = _get(
              getState(),
              'operatorLayoutReducer.accountInfo.maxAuditResponseDays',
              null,
            );
            dispatch({
              type: actionType,
              data: getLogsTableData(data, maxInspectionDays),
            });
          }
        }
      });
    return requestInfo;
  };
}

export function getLogs(filters) {
  return getAuditJson(filters, types.SET_AUDIT_JSON);
}

export function loadMoreLogs(filters) {
  return getAuditJson(filters, types.UPDATE_AUDIT_JSON, true);
}

export function getAuditCsv(filters) {
  const url = makeUrl(METHODS.GET_AUDIT_CSV, filters);
  return (dispatch) => get(url, dispatch)
    .then((response) => {
      if (response && response.success) {
        dispatch(openModalWindow(
          I18n.t('logPage.info.logHasBeenDownload'),
          MODAL_WINDOW_NOTIFY_TYPE.INFO,
        ));
      }
    });
}

export function resetPageData() {
  return (dispatch) => {
    dispatch(clearTableData());
    dispatch(resetPolicies());
    dispatch(clearClients());
    dispatch(clearOrganizations());
  };
}

function clearTableData() {
  return (dispatch) => dispatch({
    type: types.CLEAR_TABLE_DATA,
  });
}

function clearClients() {
  return (dispatch) => dispatch({
    type: types.CLEAR_CLIENT_NAMES,
  });
}

function clearOrganizations() {
  return (dispatch) => dispatch({
    type: types.CLEAR_ORGANIZATIONS_FOR_LOGS,
  });
}
