import React from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {I18n} from 'react-redux-i18n';
import {PropTypes} from 'prop-types';
import classNames from 'classnames';
import _get from 'lodash/get';

import {withStyles} from '@material-ui/core/styles';
import _isEqual from 'lodash/isEqual';
import {
  COMPRESSED_MODE_TABLE as COMPRESSED,
  FULL_WIDTH_MODE_TABLE as FULL_WIDTH,
  MODES_SHOWING_CONTAINER_WITH_LIST_AND_FORM,
  STATES_ENTITY,
  ENTER_KEY_CODE,
} from '../../constants';
import {
  MIN_TABLE_ROWS,
  LIMIT_LOAD_DATA,
  TABS as clientTabs,
  CLIENT_ACTIONS,
} from '../../app-common/Clients/constants';
import {ClientsControls} from '../index';
import {
  ContainerWithListAndForm,
  ClientsTable,
  RenderOrEmpty,
  ClientDetails, DeleteEntityWithCustomButtonsModalWindow,
} from '../../components';
import {
  loadProfiles,
  loadClients,
  loadMoreClients,
  getClientsTotal,
  resetClientsManagerData,
  setSearchOption,
  resetFilters,
  getDataToShowClientDetails,
  changeEditModeAndClientState,
  updateClient,
  resetSelectedClientIndex,
  disableClient,
  disableClientsByIdList,
  enableClient,
  enableClientsByIdList,
  forgetClient,
  forgetClientsByIdList,
  uninstallClient,
  uninstallClientsByIdList,
  cancelUninstallClient,
  exportCSV,
} from './action';
import {openModalWindow} from '../../scout-dns/layouts/Operator/action';

import style from './style';

class ClientsManager extends React.Component {
  static initState = {
    modeShowingContainer: MODES_SHOWING_CONTAINER_WITH_LIST_AND_FORM.SHOW_LIST_FULL_SCREEN,
    tableMode: FULL_WIDTH,
    prevSearchOptions: {},
    prevTableSorting: [],
    isModalWindowDisableOpened: false,
    isModalWindowDisableByIdListOpened: false,
    isModalWindowEnableByIdListOpened: false,
    isModalWindowForgetByIdListOpened: false,
    isModalWindowUninstallByIdListOpened: false,
    isModalWindowForgetOpened: false,
    isModalWindowUninstallOpened: false,
  };

  static defaultPageOptions = {
    offset: 0,
    limit: LIMIT_LOAD_DATA,
  };

  static wasDataScopeSwitched(prevProps, props) {
    return !_isEqual(prevProps.loggedAccount.accountId, props.loggedAccount.accountId)
      || !_isEqual(prevProps.currentOrganization, props.currentOrganization);
  }

  constructor(props) {
    super(props);
    this.state = {
      dataWithSelect: [],
      selectAll: false,
      isBulkActionDisabled: true,
      clientsTotal: 0,
      clientsSelected: 0,
      ...ClientsManager.initState,
    };
  }

  componentDidMount() {
    this.props.loadProfiles();
  }

  componentDidUpdate(prevProps, prevState) {
    // eslint-disable-next-line no-shadow
    const {resetClientsManagerData, loadProfiles} = this.props;
    if (ClientsManager.wasDataScopeSwitched(prevProps, this.props)) {
      resetClientsManagerData();
      this.setState({...ClientsManager.initState});
      loadProfiles();
      this.reloadClients(ClientsManager.defaultPageOptions);
    }
    if (this.state.dataWithSelect !== prevState.dataWithSelect) {
      this.setState((prevStateLocal) => {
        const filteredDataWithSelect = prevStateLocal.dataWithSelect
          .filter((entry) => entry.isSelected);
        return ({
          isBulkActionDisabled: filteredDataWithSelect.length === 0,
          clientsSelected: filteredDataWithSelect.length,
        });
      });
    }
  }

  componentWillUnmount() {
    this.props.resetClientsManagerData();
  }

  handleChangeDataWithSelect = (data) => {
    this.setState({dataWithSelect: data});
  };

  handleChangeSelectAll = (selectAll) => {
    this.setState({selectAll});
  };

  getSearchOptionsWithSorting = (searchOptions, tableSorting) => {
    const sortKeyMap = {
      osIconKey: 'os',
    };
    const sorting = tableSorting.map(({id, desc}) => {
      const modifiedId = _get(sortKeyMap, id, id);
      if (desc) {return `${modifiedId} desc`;}
      return modifiedId;
    });
    return {
      ...searchOptions,
      sorting,
      ...ClientsManager.defaultPageOptions,
    };
  };

  getContainerItemByModeShowingContainer = (modeShowingContainer) => {
    let containerItem = null;
    switch (modeShowingContainer) {
      case MODES_SHOWING_CONTAINER_WITH_LIST_AND_FORM.SHOW_LIST_FULL_SCREEN:
        containerItem = null;
        break;
      case MODES_SHOWING_CONTAINER_WITH_LIST_AND_FORM.SHOW_LIST_AND_ENTITY_DETAILS:
        containerItem = (
          <div>
            <ClientDetails
              client={this.props.selectedClient}
              handleClickButtonCancel={this.handleClickButtonCancelEditClient}
              handleClickButtonSave={this.handleClickButtonSaveClient}
              handleClickButtonBack={this.goToClientList}
              handleClickButtonEdit={this.handleClickButtonEditClient}
              handleClickButtonCancelEdit={this.handleClickButtonCancelEditClient}
              isEditMode={this.props.isEditMode}
              isValidClientInfo={this.props.isValidClientInfo}
              handleDisable={this.handleDisableClient}
              handleEnable={this.handleEnableClient}
              handleForget={this.handleForgetClient}
              handleUninstall={this.handleUninstallClient}
              handleCancelUninstall={this.handleRestoreUninstallingClient}
            />
            <RenderOrEmpty isShow={this.state.isModalWindowDisableOpened}>
              <DeleteEntityWithCustomButtonsModalWindow
                text={I18n.t('modalWindow.disableClient')}
                confirmText={I18n.t('modalWindow.disableClientButtonConfirm')}
                cancel={this.handleCancelDisable}
                confirm={this.handleConfirmDisable}
              />
            </RenderOrEmpty>
            <RenderOrEmpty isShow={this.state.isModalWindowForgetOpened}>
              <DeleteEntityWithCustomButtonsModalWindow
                text={I18n.t('modalWindow.forgetClient')}
                confirmText={I18n.t('modalWindow.forgetClientButtonConfirm')}
                cancel={this.handleCancelForget}
                confirm={this.handleConfirmForget}
              />
            </RenderOrEmpty>
            <RenderOrEmpty isShow={this.state.isModalWindowUninstallOpened}>
              <DeleteEntityWithCustomButtonsModalWindow
                text={I18n.t('modalWindow.uninstallClient')}
                confirmText={I18n.t('modalWindow.uninstallClientButtonConfirm')}
                cancel={this.handleCancelUninstallModalFormButton}
                confirm={this.handleConfirmUninstallModalFormButton}
              />
            </RenderOrEmpty>
          </div>
        );
        break;
      default:
        break;
    }
    return containerItem;
  };

  goToClientList = () => {
    this.setState({...ClientsManager.initState});
    this.props.resetSelectedClientIndex();
  };

  handleClickButtonSaveClient = () => {
    this.props.updateClient(
      this.props.updatedClient,
      this.getSearchOptionsWithSorting(this.props.searchOptions, this.state.prevTableSorting),
      I18n.t(`clients.${clientTabs.MANAGE}.roamingLabel`),
    );
  };

  handleClickButtonEditClient = () => {
    this.props.changeEditModeAndClientState(true, STATES_ENTITY.EDITING);
  };

  handleClickButtonCancelEditClient = () => {
    this.props.changeEditModeAndClientState(false, STATES_ENTITY.EDITING_CANCELED);
  };

  handleDisableClient = () => {
    this.setState({
      isModalWindowDisableOpened: true,
    });
  };

  handleCancelDisable = () => {
    this.setState({
      isModalWindowDisableOpened: false,
    });
  };

  handleConfirmDisable = () => {
    const {updatedClient, selectedClientIndex, searchOptions} = this.props;
    this.props.disableClient(
      updatedClient.id,
      selectedClientIndex,
      I18n.t(`clients.${clientTabs.MANAGE}.roamingLabel`),
    ).then(() => {
      this.reloadClients(searchOptions);
    });
    this.setState({
      isModalWindowDisableOpened: false,
    });
  };

  handleConfirmDisableByIdList = () => {
    const {dataWithSelect, selectAll} = this.state;
    const {searchOptions} = this.props;

    const idList = dataWithSelect.filter((item) => item.isSelected).map((item) => item.id);

    this.props.disableClientsByIdList(
      idList,
      searchOptions,
      selectAll,
    ).then((success) => {
      if (success) {
        this.reloadClients(searchOptions)
          .then(() => {
            this.goToClientList();
          });
      }
    });

    this.setState({
      isModalWindowDisableByIdListOpened: false,
    });
  };

  handleCancelDisableByIdList = () => {
    this.setState({
      isModalWindowDisableByIdListOpened: false,
    });
  };

  handleDisableByIdList = () => {
    this.setState({
      isModalWindowDisableByIdListOpened: true,
    });
  };

  handleEnableClient = () => {
    const {updatedClient, selectedClientIndex, searchOptions} = this.props;
    this.props.enableClient(
      updatedClient.id,
      selectedClientIndex,
      I18n.t(`clients.${clientTabs.MANAGE}.roamingLabel`),
    ).then(() => {
      this.reloadClients(searchOptions);
    });
  };

  handleConfirmEnableByIdList = () => {
    const {dataWithSelect, selectAll} = this.state;
    const {searchOptions} = this.props;

    const idList = dataWithSelect.filter((item) => item.isSelected).map((item) => item.id);

    this.props.enableClientsByIdList(
      idList,
      searchOptions,
      selectAll,
    ).then((success) => {
      if (success) {
        this.reloadClients(searchOptions)
          .then(() => {
            this.goToClientList();
          });
      }
    });

    this.setState({
      isModalWindowEnableByIdListOpened: false,
    });
  };

  handleCancelEnableByIdList = () => {
    this.setState({
      isModalWindowEnableByIdListOpened: false,
    });
  };

  handleEnableByIdList = () => {
    this.setState({
      isModalWindowEnableByIdListOpened: true,
    });
  };

  handleForgetClient = (e) => {
    e.stopPropagation();
    this.setState({
      isModalWindowForgetOpened: true,
    });
  };

  handleCancelForget = () => {
    this.setState({
      isModalWindowForgetOpened: false,
    });
  };

  handleConfirmForget = () => {
    const {searchOptions} = this.props;
    this.props.forgetClient(this.props.updatedClient.id)
      .then((success) => {
        if (success) {
          this.reloadClients(searchOptions)
            .then(() => {
              this.goToClientList();
            });
        }
      });
    this.setState({
      isModalWindowForgetOpened: false,
    });
  };

  handleConfirmForgetByIdList = () => {
    const {dataWithSelect, selectAll} = this.state;
    const {searchOptions} = this.props;

    const idList = dataWithSelect.filter((item) => item.isSelected).map((item) => item.id);

    this.props.forgetClientsByIdList(
      idList,
      searchOptions,
      selectAll,
    ).then((success) => {
      if (success) {
        this.reloadClients(searchOptions)
          .then(() => {
            this.goToClientList();
          });
      }
    });

    this.setState({
      isModalWindowForgetByIdListOpened: false,
    });
  };

  handleCancelForgetByIdList = () => {
    this.setState({
      isModalWindowForgetByIdListOpened: false,
    });
  };

  handleForgetByIdList = () => {
    this.setState({
      isModalWindowForgetByIdListOpened: true,
    });
  };

  handleUninstallClient = (e) => {
    e.stopPropagation();
    this.setState({
      isModalWindowUninstallOpened: true,
    });
  };

  handleCancelUninstallModalFormButton = () => {
    this.setState({
      isModalWindowUninstallOpened: false,
    });
  };

  handleRestoreUninstallingClient = () => {
    const {updatedClient, selectedClientIndex, searchOptions} = this.props;
    this.props.cancelUninstallClient(
      updatedClient.id,
      selectedClientIndex,
      I18n.t(`clients.${clientTabs.MANAGE}.roamingLabel`),
    ).then((success) => {
      if (success) {
        this.reloadClients(searchOptions)
          .then(() => {
            this.goToClientList();
          });
      }
    });
  };

  handleConfirmUninstallModalFormButton = () => {
    const {searchOptions} = this.props;
    this.props.uninstallClient(this.props.updatedClient.id)
      .then((success) => {
        if (success) {
          this.reloadClients(searchOptions)
            .then(() => {
              this.goToClientList();
            });
        }
      });
    this.setState({
      isModalWindowUninstallOpened: false,
    });
  };

  handleConfirmUninstallByIdList = () => {
    const {dataWithSelect, selectAll} = this.state;
    const {searchOptions} = this.props;

    const idList = dataWithSelect.filter((item) => item.isSelected).map((item) => item.id);

    this.props.uninstallClientsByIdList(
      idList,
      searchOptions,
      selectAll,
    ).then((success) => {
      if (success) {
        this.reloadClients(searchOptions)
          .then(() => {
            this.goToClientList();
          });
      }
    });

    this.setState({
      isModalWindowUninstallByIdListOpened: false,
    });
  };

  handleCancelUninstallByIdList = () => {
    this.setState({
      isModalWindowUninstallByIdListOpened: false,
    });
  };

  handleUninstallByIdList = () => {
    this.setState({
      isModalWindowUninstallByIdListOpened: true,
    });
  };

  onFilterChange = () => {
    const {searchOptions} = this.props;
    const {prevTableSorting} = this.state;
    this.setState({prevSearchOptions: searchOptions});
    this.reloadClients(this.getSearchOptionsWithSorting(searchOptions, prevTableSorting));
  };

  onResetFilters = () => {
    this.props.resetFilters();
    this.setState({prevSearchOptions: ClientsManager.initState.prevSearchOptions});
    const pageOptions = this.getSearchOptionsWithSorting(
      ClientsManager.initState.prevSearchOptions,
      this.state.prevTableSorting,
    );
    this.reloadClients(pageOptions);
  };

  onExportClients = () => {
    const {searchOptions, exportCSV} = this.props;
    exportCSV(searchOptions);
  };

  onTableStateChange = (tableState) => {
    const {
      clients,
      isLastClientsPage,
      // eslint-disable-next-line no-shadow
      loadMoreClients,
    } = this.props;
    const {prevSearchOptions, prevTableSorting} = this.state;
    const eventType = _get(tableState, 'event.type', false);
    if (eventType !== 'scroll') {
      const tableSorting = _get(tableState, 'sorted', []);
      this.setState({prevTableSorting: tableSorting});
      const pageOptions = this.getSearchOptionsWithSorting(prevSearchOptions, tableSorting);
      this.reloadClients(pageOptions);
    } else if (!isLastClientsPage) {
      const pageOptions = this.getSearchOptionsWithSorting(prevSearchOptions, prevTableSorting);
      loadMoreClients(
        {
          ...pageOptions,
          offset: clients.length,
          limit: LIMIT_LOAD_DATA,
        },
        I18n.t(`clients.${clientTabs.MANAGE}.roamingLabel`),
      );
    }
  };

  reloadClients = (searchOptions) => {
    this.props.getClientsTotal(
      searchOptions,
    ).then((response) => {
      if (response && response.data) {
        this.setState({clientsTotal: response.data.count});
      }
    });
    return this.props.loadClients(
      searchOptions,
      I18n.t(`clients.${clientTabs.MANAGE}.roamingLabel`),
    );
  };

  showClientDetails = (clientIndex) => {
    const {clients} = this.props;
    this.setState({
      modeShowingContainer: MODES_SHOWING_CONTAINER_WITH_LIST_AND_FORM.SHOW_LIST_AND_ENTITY_DETAILS,
      tableMode: COMPRESSED,
    });
    this.props.getDataToShowClientDetails(
      clients[clientIndex].id,
      clientIndex,
      I18n.t(`clients.${clientTabs.MANAGE}.roamingLabel`),
    );
  };

  render() {
    const {clients, selectedClientIndex, isEditMode, classes} = this.props;
    const {
      modeShowingContainer,
      tableMode,
      dataWithSelect,
      isBulkActionDisabled,
      selectAll,
      clientsSelected,
      clientsTotal,
    } = this.state;
    // eslint-disable-next-line max-len
    const isFullScreenList = modeShowingContainer === MODES_SHOWING_CONTAINER_WITH_LIST_AND_FORM.SHOW_LIST_FULL_SCREEN;
    const tableClasses = {
      tbody: classNames(
        {clientsTable__tbodyInfinite_noControls: !isFullScreenList},
      ),
    };
    const containerClasses = {
      list_compressed: classes.list_compressed,
      form: classes.form,
    };

    return (
      <ContainerWithListAndForm
        classes={containerClasses}
        list={(
          <div>
            <RenderOrEmpty
              isShow={isFullScreenList}
            >
              <ClientsControls
                onRefresh={this.onFilterChange}
                onReset={this.onResetFilters}
                onExport={this.onExportClients}
              />
            </RenderOrEmpty>
            <ClientsTable
              tableData={clients}
              dataWithSelect={dataWithSelect}
              selectAll={selectAll}
              handleChangeSelectAll={this.handleChangeSelectAll}
              handleChangeDataWithSelect={this.handleChangeDataWithSelect}
              customClassNames={tableClasses}
              loadData={this.onTableStateChange}
              minRows={MIN_TABLE_ROWS}
              isEditMode={isEditMode}
              mode={tableMode}
              handleClickRow={this.showClientDetails}
              selectedClientIndex={selectedClientIndex}
              isBulkActionDisabled={isBulkActionDisabled}
              handleDisableByIdList={this.handleDisableByIdList}
              handleEnableByIdList={this.handleEnableByIdList}
              handleForgetByIdList={this.handleForgetByIdList}
              handleUninstallByIdList={this.handleUninstallByIdList}
              clientsSelected={clientsSelected}
              clientsTotal={clientsTotal}
            />
            <RenderOrEmpty isShow={this.state.isModalWindowDisableByIdListOpened}>
              <DeleteEntityWithCustomButtonsModalWindow
                text={I18n.t('modalWindow.disableClientsByIdList')}
                confirmText={I18n.t('modalWindow.disableClientsByIdListButtonConfirm')}
                cancel={this.handleCancelDisableByIdList}
                confirm={this.handleConfirmDisableByIdList}
              />
            </RenderOrEmpty>
            <RenderOrEmpty isShow={this.state.isModalWindowEnableByIdListOpened}>
              <DeleteEntityWithCustomButtonsModalWindow
                text={I18n.t('modalWindow.enableClientsByIdList')}
                confirmText={I18n.t('modalWindow.enableClientsByIdListButtonConfirm')}
                cancel={this.handleCancelEnableByIdList}
                confirm={this.handleConfirmEnableByIdList}
              />
            </RenderOrEmpty>
            <RenderOrEmpty isShow={this.state.isModalWindowForgetByIdListOpened}>
              <DeleteEntityWithCustomButtonsModalWindow
                text={I18n.t('modalWindow.forgetClientsByIdList')}
                confirmText={I18n.t('modalWindow.forgetClientsByIdListButtonConfirm')}
                cancel={this.handleCancelForgetByIdList}
                isConfirmManualInput={true}
                confirmManualInputProps={{
                  // eslint-disable-next-line max-len
                  placeholder: I18n.t(`clients.${clientTabs.MANAGE}.table.clientActions.${CLIENT_ACTIONS.FORGET}`),
                  onKeyPress: (e) => {
                    // eslint-disable-next-line max-len
                    if (e.charCode === ENTER_KEY_CODE && e.target.value === I18n.t(`clients.${clientTabs.MANAGE}.table.clientActions.${CLIENT_ACTIONS.FORGET}`)) {
                      this.handleConfirmForgetByIdList();
                    }
                  },
                }}
              />
            </RenderOrEmpty>
            <RenderOrEmpty isShow={this.state.isModalWindowUninstallByIdListOpened}>
              <DeleteEntityWithCustomButtonsModalWindow
                text={I18n.t('modalWindow.uninstallClientsByIdList')}
                confirmText={I18n.t('modalWindow.uninstallClientsByIdListButtonConfirm')}
                cancel={this.handleCancelUninstallByIdList}
                isConfirmManualInput={true}
                confirmManualInputProps={{
                  // eslint-disable-next-line max-len
                  placeholder: I18n.t(`clients.${clientTabs.MANAGE}.table.clientActions.${CLIENT_ACTIONS.UNINSTALL}`),
                  onKeyPress: (e) => {
                    // eslint-disable-next-line max-len
                    if (e.charCode === ENTER_KEY_CODE && e.target.value === I18n.t(`clients.${clientTabs.MANAGE}.table.clientActions.${CLIENT_ACTIONS.UNINSTALL}`)) {
                      this.handleConfirmUninstallByIdList();
                    }
                  },
                }}
              />
            </RenderOrEmpty>
          </div>
        )}
        form={this.getContainerItemByModeShowingContainer(modeShowingContainer)}
      />
    );
  }
}

ClientsManager.propTypes = {
  classes: PropTypes.object.isRequired,
  loggedAccount: PropTypes.object.isRequired,
  currentOrganization: PropTypes.object,
  clients: PropTypes.array.isRequired,
  isLastClientsPage: PropTypes.bool.isRequired,
  searchOptions: PropTypes.object.isRequired,
  selectedClient: PropTypes.object.isRequired,
  updatedClient: PropTypes.object.isRequired,
  isValidClientInfo: PropTypes.bool.isRequired,
  isEditMode: PropTypes.bool.isRequired,
  selectedClientIndex: PropTypes.number,

  loadProfiles: PropTypes.func.isRequired,
  loadClients: PropTypes.func.isRequired,
  getClientsTotal: PropTypes.func.isRequired,
  loadMoreClients: PropTypes.func.isRequired,
  setSearchOption: PropTypes.func.isRequired,
  resetClientsManagerData: PropTypes.func.isRequired,
  resetFilters: PropTypes.func.isRequired,
  getDataToShowClientDetails: PropTypes.func.isRequired,
  disableClient: PropTypes.func.isRequired,
  disableClientsByIdList: PropTypes.func.isRequired,
  enableClient: PropTypes.func.isRequired,
  enableClientsByIdList: PropTypes.func.isRequired,
  forgetClient: PropTypes.func.isRequired,
  forgetClientsByIdList: PropTypes.func.isRequired,
  uninstallClient: PropTypes.func.isRequired,
  uninstallClientsByIdList: PropTypes.func.isRequired,
  updateClient: PropTypes.func.isRequired,
  cancelUninstallClient: PropTypes.func.isRequired,
  resetSelectedClientIndex: PropTypes.func.isRequired,
  changeEditModeAndClientState: PropTypes.func.isRequired,
  exportCSV: PropTypes.func.isRequired,
};

ClientsManager.defaultProps = {
  currentOrganization: null,
  selectedClientIndex: null,
};

const mapStateToProps = (state) => ({
  loggedAccount: state.userAccountsReducer.loggedAccount,
  currentOrganization: state.userOrganizationsReducer.currentOrganization,

  clients: state.clientsManagerReducer.tableData,
  isLastClientsPage: state.clientsManagerReducer.isLastClientsPage,
  searchOptions: state.clientsManagerReducer.searchOptions,
  selectedClient: state.clientsManagerReducer.selectedClient,
  selectedClientIndex: state.clientsManagerReducer.selectedClientIndex,
  isEditMode: state.clientsManagerReducer.isEditMode,

  updatedClient: state.clientEditFormReducer.editableClientInfo,
  isValidClientInfo: state.clientEditFormReducer.isValidClientInfo,
});

const mapDispatchToProps = (dispatch) => ({
  loadProfiles: bindActionCreators(loadProfiles, dispatch),
  loadClients: bindActionCreators(loadClients, dispatch),
  getClientsTotal: bindActionCreators(getClientsTotal, dispatch),
  loadMoreClients: bindActionCreators(loadMoreClients, dispatch),
  setSearchOption: bindActionCreators(setSearchOption, dispatch),
  resetClientsManagerData: bindActionCreators(resetClientsManagerData, dispatch),
  resetFilters: bindActionCreators(resetFilters, dispatch),

  changeEditModeAndClientState: bindActionCreators(changeEditModeAndClientState, dispatch),
  updateClient: bindActionCreators(updateClient, dispatch),
  getDataToShowClientDetails: bindActionCreators(getDataToShowClientDetails, dispatch),
  openModalWindow: bindActionCreators(openModalWindow, dispatch),
  resetSelectedClientIndex: bindActionCreators(resetSelectedClientIndex, dispatch),

  disableClient: bindActionCreators(disableClient, dispatch),
  disableClientsByIdList: bindActionCreators(disableClientsByIdList, dispatch),
  enableClient: bindActionCreators(enableClient, dispatch),
  enableClientsByIdList: bindActionCreators(enableClientsByIdList, dispatch),
  forgetClient: bindActionCreators(forgetClient, dispatch),
  forgetClientsByIdList: bindActionCreators(forgetClientsByIdList, dispatch),
  uninstallClient: bindActionCreators(uninstallClient, dispatch),
  uninstallClientsByIdList: bindActionCreators(uninstallClientsByIdList, dispatch),
  cancelUninstallClient: bindActionCreators(cancelUninstallClient, dispatch),
  exportCSV: bindActionCreators(exportCSV, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(style)(ClientsManager));
