import PropTypes from 'prop-types';
import React from 'react';
import {I18n} from 'react-redux-i18n';
import {withStyles} from '@material-ui/core/styles';

import {CardWithHeader} from '../index';
import style from './style';

function PoliciesAssignedTable({classes, policies}) {
  const contentForRendering = policies.length > 0
    ? (
      policies.map((policy) => (
        <div className={classes.policiesAssignedTable__row} key={policy.id}>
          {policy.name}
        </div>
      ))
    )
    : (
      <div className={classes.policiesAssignedTable__noPoliciesFound}>
        {I18n.t('customListsPage.policiesAssignedTable.noPoliciesFound')}
      </div>
    );
  return (
    <CardWithHeader
      cardTitle={I18n.t('customListsPage.policiesAssignedTable.title')}
      cardTitleColor="primary"
      customCardClasses="card_policiesAssignedTable"
      customCardContentClasses="card__content_policiesAssignedTable"
      content={contentForRendering}
    />
  );
}

PoliciesAssignedTable.propTypes = {
  policies: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
  })).isRequired,
  classes: PropTypes.object.isRequired,
};

export default withStyles(style)(PoliciesAssignedTable);
