import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {v4 as uuid} from 'uuid';

import {
  arrayMove,
  SortableContainer,
  SortableElement,
  SortableHandle,
} from 'react-sortable-hoc';

import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';

import {Check} from '../../icons/index';

const DragHandle = SortableHandle((props) => <span className={props.classes.columnPickerItem__icon} />);

const SortableItem = SortableElement((props) => (
  <div className={props.classes.columnPicker__item}>
    <FormControlLabel
      control={(
        <Checkbox
          disabled={props.value.isRequired}
          tabIndex={-1}
          checked={props.value.isShow}
          onClick={props.handleCheckboxChange}
          checkedIcon={<Check className={props.classes.checkbox__icon_checked} />}
          icon={<Check className={props.classes.checkbox__icon_unchecked} />}
          name={props.value.accessor}
          classes={{
            checked: props.classes.checkbox_checked,
          }}
        />
      )}
      classes={{
        label: props.classes.columnPickerItem__label,
      }}
      label={props.value.Header}
      key={uuid()}
    />
    <DragHandle classes={props.classes} />
  </div>
));

const SortableList = SortableContainer((props) => (
  <div className={props.classes.columnPicker__items}>
    {props.items.map((value, index) => (
      <SortableItem
        classes={props.classes}
        key={uuid()}
        index={index}
        value={value}
        handleCheckboxChange={props.handleCheckboxChange}
      />
    ))}
  </div>
));

class SortableColumnsList extends Component {
  onSortEnd = ({oldIndex, newIndex}) => {
    const {items} = this.props;
    this.props.handleMove(arrayMove(items, oldIndex, newIndex));
  };

  render() {
    const {
      classes,
      items,
    } = this.props;

    return (
      <SortableList
        classes={classes}
        items={items}
        handleCheckboxChange={this.props.handleCheckboxChange}
        onSortEnd={this.onSortEnd}
        useDragHandle={true}
      />
    );
  }
}

SortableColumnsList.propTypes = {
  classes: PropTypes.object.isRequired,
  items: PropTypes.array.isRequired,
  handleMove: PropTypes.func.isRequired,
  handleCheckboxChange: PropTypes.func.isRequired,
};

SortableColumnsList.defaultProps = {};

export default SortableColumnsList;
