import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {I18n} from 'react-redux-i18n';
import {v4 as uuid} from 'uuid';
import _isEqual from 'lodash/isEqual';
import _isEmpty from 'lodash/isEmpty';
import _isNil from 'lodash/isNil';
import _cloneDeep from 'lodash/cloneDeep';

import {withStyles} from '@material-ui/core/styles';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';

import {
  CustomInput,
  NavPills,
} from '../../material-dashboard-pro-react/components/index';

import {ModalWindow, PoliciesAssignedTable, RenderOrEmpty} from '../../components/index';

import {
  getEditableWhiteBlackListInfo,
  setValidationResultWhiteBlackListInfo,
  resetWhiteBlackListEditFormData,
  updateWhiteBlackListInfo,
} from './action';

import {
  isNotEmpty,
  validate,
} from '../../utils/validators';

import {
  MODAL_WINDOW_NOTIFY_TYPE,
  REG_EXR,
  STATES_ENTITY,
  ENTITY_OPENING_MODE, ACCESS_RIGHTS,
} from '../../constants';

import {Check} from '../../icons/index';

import style from './style';

import {openModalWindow} from '../../scout-dns/layouts/Operator/action';
import {RightAvailability} from '../index';

const DEFAULT_ROWS_TEXT_AREA = 2;

const rightAvailabilityMap = {
  makeGlobalCheckbox: [ACCESS_RIGHTS.CUSTOM_LIST_MAKE_GLOBAL],
};

class WhiteBlackListEditForm extends Component {
  static initialLocalState = {
    validation: {
      name: true,
      description: true,
      allowed: true,
      blocked: true,
    },
    invalidDomain: '',
    isGlobalPended: false,
  };

  static shouldUpdateEditableObject(prevProps, props) {
    return !_isEqual(prevProps.whiteBlackList, props.whiteBlackList);
  }

  static shouldResetFormData(prevProps, props) {
    return !_isEqual(prevProps.isEditMode, props.isEditMode)
      && _isEqual(props.whiteBlackListState, STATES_ENTITY.EDITING_CANCELED);
  }

  constructor(props) {
    super(props);
    this.state = WhiteBlackListEditForm.initialLocalState;
  }

  componentDidMount() {
    this.props.getEditableWhiteBlackListInfo(this.props.whiteBlackList);
  }

  shouldComponentUpdate(nextProps, nextState) {
    return !_isEqual(this.props.whiteBlackList, nextProps.whiteBlackList)
      || !_isEqual(this.props.isEditMode, nextProps.isEditMode)
      || !_isEqual(this.props.editableWhiteBlackListInfo, nextProps.editableWhiteBlackListInfo)
      || !_isEqual(this.props.policiesAssigned, nextProps.policiesAssigned)
      || !_isEqual(this.state.isGlobalPended, nextState.isGlobalPended);
  }

  componentDidUpdate(prevProps) {
    if (WhiteBlackListEditForm.shouldUpdateEditableObject(prevProps, this.props)
      || WhiteBlackListEditForm.shouldResetFormData(prevProps, this.props)) {
      this.props.getEditableWhiteBlackListInfo(this.props.whiteBlackList);
    }
    if (WhiteBlackListEditForm.shouldResetFormData(prevProps, this.props)) {
      this.resetValidation();
    }
  }

  componentWillUnmount() {
    this.props.resetWhiteBlackListEditFormData();
  }

  getButtonsColor = () => {
    const {isEditMode} = this.props;
    if (isEditMode) {
      return 'edit';
    }
    return 'secondary';
  };

  validateListInfo = (data) => validate(
    [
      {
        name: 'name',
        validationRules: [isNotEmpty],
      },
      {
        name: 'description',
        validationRules: [],
      },
    ],
    data,
    {
      name: true,
      description: true,
      allowed: this.state.validation.allowed,
      blocked: this.state.validation.blocked,
    },
  );

  resetValidation = () => {
    this.props.setValidationResultWhiteBlackListInfo(true);
    this.setState(WhiteBlackListEditForm.initialLocalState);
  };

  isValidForm = (fieldName, isValidChangedField) => {
    const currentValidationState = _cloneDeep(this.state.validation);
    currentValidationState[fieldName] = isValidChangedField;
    return Object.keys(currentValidationState).reduce(
      (result, field) => result && currentValidationState[field],
      true,
    );
  };

  handleInputChange = (e) => {
    const {editableWhiteBlackListInfo} = this.props;
    const validationResult = this.validateListInfo({
      ...this.props.editableWhiteBlackListInfo,
      [e.target.name]: e.target.value,
    });
    this.props.updateWhiteBlackListInfo(
      {
        ...editableWhiteBlackListInfo,
        [e.target.name]: e.target.value,
      },
      this.isValidForm(e.target.name, validationResult.isValid),
    );
    this.setState({
      validation: validationResult.validationState,
    });
  };

  validateDomainList = (domainsAsString) => {
    if (_isEqual(domainsAsString, '')) {
      return {
        isValid: true,
        invalidDomain: '',
      };
    }

    const domainsAsArray = domainsAsString.trim().split(REG_EXR.DOMAIN_SPLIT_PARAM);
    const {accountInfo} = this.props;
    if (domainsAsArray.length > accountInfo.maxDomainsPerWBList) {
      const invalidDomainsRestrictions = I18n.t(
        'whitelistPage.errorMessages.domainsListMoreThenMaxDomains',
        {
          domains: domainsAsArray.length,
          maxDomains: accountInfo.maxDomainsPerWBList,
        },
      );
      this.props.openModalWindow(invalidDomainsRestrictions, MODAL_WINDOW_NOTIFY_TYPE.INFO);
      return {
        isValid: false,
        invalidDomainsRestrictions: invalidDomainsRestrictions,
      };
    }
    for (let i = 0; i < domainsAsArray.length; i++) {
      if (!(REG_EXR.WHITE_BLACK_LIST_DOMAIN).test(domainsAsArray[i].trim())) {
        return {
          isValid: false,
          invalidDomain: domainsAsArray[i],
        };
      }
    }
    return {
      isValid: true,
      invalidDomain: '',
    };
  };

  handleDomainsListChange = (e) => {
    const {name, value} = e.target;
    const {editableWhiteBlackListInfo} = this.props;
    const validationResult = this.validateDomainList(value);

    this.props.updateWhiteBlackListInfo(
      {
        ...editableWhiteBlackListInfo,
        [name]: value,
      },
      this.isValidForm(e.target.name, validationResult.isValid),
    );
    this.setState((prevState) => ({
      validation: {
        ...prevState.validation,
        [name]: validationResult.isValid,
      },
      invalidDomain: validationResult.invalidDomain,
      invalidDomainsRestrictions: validationResult.invalidDomainsRestrictions,
    }));
  };

  handleCheckboxChange = (e) => {
    const {editableWhiteBlackListInfo, policiesAssigned} = this.props;
    if (!editableWhiteBlackListInfo.isGlobal && e.target.checked && !_isEmpty(policiesAssigned)) {
      this.setState({isGlobalPended: true});
    } else {
      this.setGlobalStatus(e.target.checked);
    }
  };

  setGlobalStatus = (isGlobal) => {
    const {editableWhiteBlackListInfo} = this.props;
    this.props.updateWhiteBlackListInfo(
      {
        ...editableWhiteBlackListInfo,
        isGlobal,
      },
      true,
    );
    this.setState({isGlobalPended: false});
  };

  resetConfirmationState = () => {
    this.setState({isGlobalPended: WhiteBlackListEditForm.initialLocalState.isGlobalPended});
  };

  render() {
    const {
      classes,
      editableWhiteBlackListInfo,
      isEditMode,
      mode,
      policiesAssigned,
      currentOrganization,
    } = this.props;

    const {
      invalidDomain,
      invalidDomainsRestrictions,
      validation,
      isGlobalPended,
    } = this.state;

    const propsFormControl = {
      fullWidth: true,
    };
    const propsInput = {
      name: {
        disabled: !isEditMode,
        value: editableWhiteBlackListInfo.name,
        name: 'name',
        onChange: this.handleInputChange,
        type: 'text',
      },
      description: {
        disabled: !isEditMode,
        value: editableWhiteBlackListInfo.description,
        name: 'description',
        onChange: this.handleInputChange,
        type: 'text',
      },
      allowed: {
        disabled: !isEditMode,
        placeholder: I18n.t('whitelistPage.domainsPlaceholder'),
        multiline: true,
        rows: DEFAULT_ROWS_TEXT_AREA,
        value: editableWhiteBlackListInfo.allowed,
        name: 'allowed',
        onChange: this.handleDomainsListChange,
        type: 'text',
        className: classes.form__textArea,
      },
      blocked: {
        disabled: !isEditMode,
        placeholder: I18n.t('whitelistPage.domainsPlaceholder'),
        multiline: true,
        rows: DEFAULT_ROWS_TEXT_AREA,
        value: editableWhiteBlackListInfo.blocked,
        name: 'blocked',
        onChange: this.handleDomainsListChange,
        type: 'text',
        className: classes.form__textArea,
      },
    };
    const checkboxClasses = {
      checked: classes.checked,
    };
    const checkboxFormControlClasses = {
      root: classes.label,
    };
    let allowErrorText;
    if (!validation.allowed && invalidDomain) {
      allowErrorText = I18n.t(
        'whitelistPage.errorMessages.domainsListIsNotValid',
        {
          domain: invalidDomain,
        },
      );
    }
    if (!validation.allowed && invalidDomainsRestrictions) {
      allowErrorText = invalidDomainsRestrictions;
    }
    let blockErrorText;
    if (!validation.block && invalidDomain) {
      blockErrorText = I18n.t(
        'whitelistPage.errorMessages.domainsListIsNotValid',
        {
          domain: invalidDomain,
        },
      );
    }
    if (!validation.block && invalidDomainsRestrictions) {
      blockErrorText = invalidDomainsRestrictions;
    }

    const isShowGLobalCheckbox = _isNil(currentOrganization)
      && _isNil(editableWhiteBlackListInfo.organizationId);

    return (
      <NavPills
        color={this.getButtonsColor()}
        tabs={[
          {
            tabButton: I18n.t('whitelistPage.tabInfo'),
            tabContent: (
              <>
                <ModalWindow
                  isOpen={isGlobalPended}
                  confirm={() => this.setGlobalStatus(true)}
                  cancel={this.resetConfirmationState}
                  title={I18n.t('whitelistPage.confirmations.makeGlobal')}
                />
                <CustomInput
                  errorText={validation.name
                    ? undefined
                    : I18n.t('whitelistPage.errorMessages.nameIsNotValid')}
                  error={!validation.name}
                  formControlProps={propsFormControl}
                  id={uuid()}
                  inputProps={propsInput.name}
                  labelText={I18n.t('whitelistPage.listName')}
                />
                <CustomInput
                  formControlProps={propsFormControl}
                  id={uuid()}
                  inputProps={propsInput.description}
                  labelText={I18n.t('whitelistPage.listDescription')}
                />
                <RightAvailability accessRights={rightAvailabilityMap.makeGlobalCheckbox}>
                  <RenderOrEmpty isShow={isShowGLobalCheckbox}>
                    <FormControlLabel
                      control={(
                        <Checkbox
                          disabled={!isEditMode}
                          tabIndex={-1}
                          checked={editableWhiteBlackListInfo.isGlobal}
                          onClick={this.handleCheckboxChange}
                          checkedIcon={<Check className={classes.checkedIcon} />}
                          icon={<Check className={classes.uncheckedIcon} />}
                          name="isGlobal"
                          classes={checkboxClasses}
                        />
                      )}
                      classes={checkboxFormControlClasses}
                      label={I18n.t('whitelistPage.isGlobal')}
                    />
                  </RenderOrEmpty>
                </RightAvailability>
                <RenderOrEmpty isShow={mode === ENTITY_OPENING_MODE.EDIT}>
                  <PoliciesAssignedTable policies={policiesAssigned} />
                </RenderOrEmpty>
              </>
            ),
          },
          {
            tabButton: I18n.t('whitelistPage.tabAllow'),
            tabContent: (
              <CustomInput
                errorText={allowErrorText}
                error={!validation.allowed}
                formControlProps={propsFormControl}
                id={uuid()}
                inputProps={propsInput.allowed}
              />
            ),
          },
          {
            tabButton: I18n.t('whitelistPage.tabBlock'),
            tabContent: (
              <CustomInput
                errorText={blockErrorText}
                error={!validation.blocked}
                id={uuid()}
                formControlProps={propsFormControl}
                inputProps={propsInput.blocked}
              />
            ),
          },
        ]}
      />
    );
  }
}

WhiteBlackListEditForm.propTypes = {
  accountInfo: PropTypes.object.isRequired,
  currentOrganization: PropTypes.object,
  classes: PropTypes.object.isRequired,
  editableWhiteBlackListInfo: PropTypes.object.isRequired,
  isEditMode: PropTypes.bool.isRequired,
  whiteBlackList: PropTypes.object.isRequired,
  policiesAssigned: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string.isRequired,
  })).isRequired,
  mode: PropTypes.oneOf(Object.values(ENTITY_OPENING_MODE)).isRequired,

  getEditableWhiteBlackListInfo: PropTypes.func.isRequired,
  openModalWindow: PropTypes.func.isRequired,
  setValidationResultWhiteBlackListInfo: PropTypes.func.isRequired,
  resetWhiteBlackListEditFormData: PropTypes.func.isRequired,
  updateWhiteBlackListInfo: PropTypes.func.isRequired,
};

WhiteBlackListEditForm.defaultProps = {
  currentOrganization: null,
};

const mapStateToProps = (state) => ({
  whiteBlackList: state.whiteBlackListReducer.selectedWhiteBlackList,
  whiteBlackListState: state.whiteBlackListReducer.whiteBlackListState,
  isEditMode: state.whiteBlackListReducer.isEditMode,
  policiesAssigned: state.whiteBlackListReducer.policiesAssigned,

  accountInfo: state.operatorLayoutReducer.accountInfo,

  currentOrganization: state.userOrganizationsReducer.currentOrganization,

  editableWhiteBlackListInfo: state.whiteBlackListEditFormReducer.editableWhiteBlackListInfo,
});

const mapDispatchToProps = (dispatch) => ({
  getEditableWhiteBlackListInfo: bindActionCreators(getEditableWhiteBlackListInfo, dispatch),
  setValidationResultWhiteBlackListInfo: bindActionCreators(
    setValidationResultWhiteBlackListInfo,
    dispatch,
  ),
  openModalWindow: bindActionCreators(openModalWindow, dispatch),
  resetWhiteBlackListEditFormData: bindActionCreators(resetWhiteBlackListEditFormData, dispatch),
  updateWhiteBlackListInfo: bindActionCreators(updateWhiteBlackListInfo, dispatch),
});

const WhiteBlackListEditFormWithStyles = withStyles(style)(WhiteBlackListEditForm);
export default connect(mapStateToProps, mapDispatchToProps)(WhiteBlackListEditFormWithStyles);
