import _cloneDeep from 'lodash/cloneDeep';
import _isEmpty from 'lodash/isEmpty';
import _isEqual from 'lodash/isEqual';

import {REG_EXR} from '../constants';

function isNotEmpty(str) {
  return !!str;
}

function arrayIsNotEmpty(arr) {
  return !_isEmpty(arr);
}

function is2FACode(str) {
  return !_isEmpty(str) && _isEqual(str.length, 6);
}

function isPositiveInt(str) {
  return REG_EXR.POSITIVE_INT.test(str);
}

function isNonNegativeReal(str) {
  return REG_EXR.NON_NEGATIVE_REAL.test(str);
}

function isEmail(str) {
  return REG_EXR.EMAIL.test(str);
}

function isURL(str) {
  return REG_EXR.URL.test(str);
}

function isValidIpAndCidrMask(str) {
  return REG_EXR.IP_V4_CIDR.test(str) || REG_EXR.IP_V6_CIDR.test(str);
}

function isValidUUID(str) {
  return REG_EXR.UUIDV4.test(str);
}

function okValidationResult() {
  return {
    validationState: {},
    isValid: true,
  };
}

function areAllNotEmpty(array) {
  return array.map((str) => !!str);
}

function areAllValidIp(array) {
  return array.map((str) => REG_EXR.IP_V4.test(str) || REG_EXR.IP_V6.test(str));
}

function validate(validationArray, data, validationState) {
  const newValidationState = _cloneDeep(validationState);
  const isValid = validationArray.reduce(
    (result, rule) => {
      const value = data[rule.name];
      const isFieldValid = rule.validationRules.reduce(
        (_result, func) => {
          if (rule.type === 'array') {
            const itemsValidation = func(value);
            const _isValid = _result
              && itemsValidation.reduce((acc, itemValid) => acc && itemValid, true);
            newValidationState[rule.name] = itemsValidation;
            return _isValid;
          }
          const _isValid = _result && func(value);
          newValidationState[rule.name] = _isValid;
          return _isValid;
        },
        true,
      );
      return result && isFieldValid;
    },
    true,
  );
  return {
    isValid: isValid,
    validationState: newValidationState,
  };
}

export {
  isNotEmpty,
  arrayIsNotEmpty,
  is2FACode,
  isPositiveInt,
  isNonNegativeReal,
  validate,
  isEmail,
  okValidationResult,
  isValidIpAndCidrMask,
  areAllNotEmpty,
  areAllValidIp,
  isURL,
  isValidUUID,
};
