import {
  defaultFont,
  greenColor,
  primaryColor,
  redColor,
  yellowColor,
} from '../../styles/style';

const cardStats = {
  borderTop: 'none',
  textAlign: 'center',
};

const style = {
  cardStats: {
    height: '85px',
    boxShadow: 'none',
  },
  cardStats__content: {
    textAlign: 'center',
    padding: '0',
  },
  cardStats__description: {
    'color': primaryColor,
    ...defaultFont,
    'fontSize': '15px',
    'margin': 0,
    '@media (max-width: 1445px)': {
      fontSize: '12px',
    },
    '@media (max-width: 1170px)': {
      fontSize: '11px',
    },
  },
  cardStats__value: {
    ...defaultFont,
    'fontSize': '35px',
    'fontWeight': 'normal',
    'margin': '0 auto',
    'maxWidth': '175px',
    'overflow': 'hidden',
    'textOverflow': 'ellipsis',
    '@media (max-width: 1445px)': {
      fontSize: '25px',
      maxWidth: '150px',
    },
    '@media (max-width: 1170px)': {
      fontSize: '20px',
      maxWidth: '100px',
    },
  },
  cardStats__borderTop_green: {
    ...cardStats,
    color: greenColor,
  },
  cardStats__borderTop_primary: {
    ...cardStats,
    color: primaryColor,
  },
  cardStats__borderTop_red: {
    ...cardStats,
    color: redColor,
  },
  cardStats__borderTop_yellow: {
    ...cardStats,
    color: yellowColor,
  },
  cardStats__tooltip: {
    background: primaryColor,
    color: '#FFF',
    fontSize: 17,
  },
  cardStats__tooltipPopper: {},
};

export default style;
