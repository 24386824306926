import initState from '../../reducers/redux-initial-state';

import {
  getSettingsAsObjects,
  getPolicyCopy,
  getTabScheme,
  getUpdatedSettingsObject,
  getUpdatedCategoriesObject,
} from './service';

import {types} from './action';

export default (state = initState.policyEditFormReducer, action) => {
  switch (action.type) {
    case types.COPY_POLICY: {
      const policyCopy = getPolicyCopy(action.newPolicy, action.currentPolicy);
      return {
        ...state,
        advancedSettingsObject: policyCopy.advancedSettingsObject,
        baseSettingsObject: policyCopy.baseSettingsObject,
        categoriesObject: policyCopy.categoriesObject,
        strategyStatusesObject: policyCopy.strategyStatusesObject,
        tldFilteringSettingsObject: policyCopy.tldFilteringSettingsObject,
      };
    }
    case types.RESET_POLICY_FORM_VALUES: {
      return {
        ...state,
        advancedSettingsObject: initState.policyEditFormReducer.advancedSettingsObject,
        baseSettingsObject: initState.policyEditFormReducer.baseSettingsObject,
        categoriesObject: initState.policyEditFormReducer.categoriesObject,
        strategyStatusesObject: initState.policyEditFormReducer.strategyStatusesObject,
        tldFilteringSettingsObject: initState.policyEditFormReducer.tldFilteringSettingsObject,
        policyTemplateToCreate: initState.policyEditFormReducer.policyTemplateToCreate,
      };
    }
    case types.RESET_SELECTED_POLICY_TEMPLATE_INDEX: {
      return {
        ...state,
        selectedPolicyTemplateIndex: initState.policyEditFormReducer.selectedPolicyTemplateIndex,
      };
    }
    case types.SET_THREATS_TAB_FORM_SCHEME: {
      return {
        ...state,
        threatsTabFormScheme: getTabScheme(
          action.data,
          action.groupName,
        ),
      };
    }
    case types.SET_SELECTED_POLICY_TEMPLATE_INDEX: {
      return {
        ...state,
        selectedPolicyTemplateIndex: action.index,
      };
    }
    case types.SET_CONTENT_TAB_FORM_SCHEME: {
      return {
        ...state,
        contentTabFormScheme: getTabScheme(
          action.data,
          action.groupName,
        ),
      };
    }
    case types.SET_APPLICATIONS_TAB_FORM_SCHEME: {
      return {
        ...state,
        applicationsTabFormScheme: getTabScheme(
          action.data,
          action.groupName,
        ),
      };
    }
    case types.SET_POLICY_FORM_VALUES: {
      const settingsAsObjects = getSettingsAsObjects(action.data);
      return {
        ...state,
        advancedSettingsObject: settingsAsObjects.advancedSettingsObject,
        baseSettingsObject: settingsAsObjects.baseSettingsObject,
        categoriesObject: settingsAsObjects.categoriesObject,
        tldFilteringSettingsObject: settingsAsObjects.tldFilteringSettingsObject,
        strategyStatusesObject: settingsAsObjects.strategyStatusesObject,
      };
    }
    case types.UPDATE_CATEGORIES_STATUS: {
      return {
        ...state,
        categoriesObject: getUpdatedCategoriesObject(
          state.categoriesObject,
          action.categoryName,
          action.categoryStatus,
        ),
      };
    }
    case types.UPDATE_ADVANCED_SETTING: {
      return {
        ...state,
        advancedSettingsObject: getUpdatedSettingsObject(
          state.advancedSettingsObject,
          action.settingName,
          action.settingObject,
        ),
      };
    }
    case types.UPDATE_BASE_SETTING: {
      return {
        ...state,
        baseSettingsObject: getUpdatedSettingsObject(
          state.baseSettingsObject,
          action.settingName,
          action.settingObject,
        ),
      };
    }
    default:
      return state;
  }
};
