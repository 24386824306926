import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {v4 as uuid} from 'uuid';

import {withStyles} from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import {RenderOrEmpty} from '../index';

import style from './style';

function CustomTable(props) {
  const {
    classes,
    tableHead,
    tableData,
    tableHeaderColor,
    hover,
    striped,
  } = props;

  const getHeadCells = () => tableHead.map((head) => (
    <TableCell
      className={classNames(
        classes[tableHeaderColor],
        classes.tableHeadCell,
        classes.tableCell,
        classes.tableHeadFontSize,
      )}
      key={uuid()}
    >
      {head}
    </TableCell>
  ));

  const getBodyCells = (rowData) => rowData.map((bodyCellData) => (
    <TableCell
      className={classNames(classes.tableCell)}
      key={uuid()}
    >
      {bodyCellData}
    </TableCell>
  ));

  const getRows = () => tableData.map((row, key) => {
    let rowColor = '';
    let rowColored = false;
    if (row.color !== undefined) {
      rowColor = row.color;
      rowColored = true;
    }
    const tableRowClasses = classNames({
      [classes.tableRowHover]: hover,
      [classes[`${rowColor}Row`]]: rowColored,
      [classes.tableStripedRow]: striped && key % 2 === 0,
    });
    return (
      <TableRow
          key={uuid()}
          hover={hover}
          className={classNames(
            classes.tableRow,
            tableRowClasses,
          )}
      >
        {getBodyCells(row)}
      </TableRow>
    );
  });

  return (
    <div className={classNames(classes.tableResponsive)}>
      <Table className={classes.table}>
        <RenderOrEmpty isShow={tableHead !== undefined}>
          <TableHead>
            <TableRow className={classes.tableRow}>
              {getHeadCells()}
            </TableRow>
          </TableHead>
        </RenderOrEmpty>
        <TableBody>
          {getRows()}
        </TableBody>
      </Table>
    </div>
  );
}

CustomTable.defaultProps = {
  tableHeaderColor: 'primary',
  hover: false,
  striped: false,
  tableHead: [],
  tableData: [],
};

CustomTable.propTypes = {
  classes: PropTypes.object.isRequired,
  tableHeaderColor: PropTypes.oneOf([
    'primary',
    'secondary',
    'yellow',
    'red',
    'green',
    'gray',
  ]),
  tableHead: PropTypes.arrayOf(PropTypes.string),
  tableData: PropTypes.array,
  hover: PropTypes.bool,
  striped: PropTypes.bool,
};

export default withStyles(style)(CustomTable);
