import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import _isEqual from 'lodash/isEqual';

import {DOMAIN_TYPES, TABS} from '../../app-common/Insights/constants';
import {getInsightsDomainsData} from '../../app-common/Insights/selectors';
import {getDomainLogs, resetDomainLogs} from './action';
import {DomainInsightsTable} from '../index';

import './style.css';

class InsightsDomainLogs extends React.PureComponent {
  componentDidMount() {
    this.getDomains();
  }

  componentDidUpdate(prevProps) {
    if (!_isEqual(prevProps.filterData, this.props.filterData)) {
      this.getDomains();
    }
  }

  componentWillUnmount() {
    this.props.resetDomainLogs();
  }

  getDomains = () => {
    const {
      tab,
      filterData,
    } = this.props;
    this.props.getDomainLogs(tab, filterData);
  };

  render() {
    const tableStyle = {
      flexGrow: 1,
      height: 'calc(100vh - 235px)',
      minHeight: '300px',
    };
    return (
      <DomainInsightsTable
        data={this.props.domains}
        showLogs={this.props.showLogs}
        showFqdns={this.props.showFqdns}
        tableStyle={tableStyle}
      />
    );
  }
}

InsightsDomainLogs.propTypes = {
  tab: PropTypes.oneOf(Object.values(TABS)).isRequired,
  domains: PropTypes.array.isRequired,
  filterData: PropTypes.object,
  getDomainLogs: PropTypes.func.isRequired,
  resetDomainLogs: PropTypes.func.isRequired,
  showLogs: PropTypes.func.isRequired,
  showFqdns: PropTypes.func.isRequired,
};

InsightsDomainLogs.defaultProps = {
  filterData: {},
};

const mapDispatchToProps = {
  getDomainLogs,
  resetDomainLogs,
};

const mapStateToProps = (state, props) => ({
  domains: getInsightsDomainsData(props.tab, DOMAIN_TYPES.DOMAIN)(state),
});

export default connect(mapStateToProps, mapDispatchToProps)(InsightsDomainLogs);
