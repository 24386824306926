import React from 'react';
import PropTypes from 'prop-types';
import {I18n} from 'react-redux-i18n';

import {withStyles} from '@material-ui/core/styles';

import {
  GroupStatsCard,
  CardStats,
} from '../index';
import {LoadingLayout} from '../../layouts-common';

import style from './style';

function ClientCountStats(props) {
  const {
    classes,
    online,
    offline,
    widgetKey,
  } = props;
  return (
    <GroupStatsCard
        groupName={I18n.t('dashboard.clientCountStats.title')}
        customCardClassName="card_status"
        content={(
          <LoadingLayout widgetKey={widgetKey}>
            <div className={classes.container}>
              <div className={classes.item}>
                <CardStats
                  description={I18n.t('dashboard.clientCountStats.online')}
                  borderTopColor="green"
                  value={online}
                />
              </div>
              <div className={classes.item}>
                <CardStats
                  description={I18n.t('dashboard.clientCountStats.offline')}
                  borderTopColor="red"
                  value={offline}
                />
              </div>
            </div>
          </LoadingLayout>
        )}
    />
  );
}

ClientCountStats.propTypes = {
  classes: PropTypes.object.isRequired,
  networksUp: PropTypes.number,
  networksDown: PropTypes.number,
  online: PropTypes.number,
  offline: PropTypes.number,
  widgetKey: PropTypes.string.isRequired,
};
ClientCountStats.defaultProps = {
  networksUp: 0,
  networksDown: 0,
  online: 0,
  offline: 0,
};

export default withStyles(style)(ClientCountStats);
