import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {I18n} from 'react-redux-i18n';
import _isNull from 'lodash/isNull';
import _isUndefined from 'lodash/isUndefined';
import _findIndex from 'lodash/findIndex';
import _isEqual from 'lodash/isEqual';

import {withStyles} from '@material-ui/core/styles';

import {Language} from '../../icons';

import {
  ButtonExpandLessMore,
  CardWithHeader,
  Dropdown,
  getPolicyEditFormComponent,
  RenderOrEmpty,
} from '../index';

import style from './style';

class PolicySettingsTab extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isShowAdvancedSection: false,
    };
  }

  getPolicyTemplateDropdownOptions = () => {
    const {policies} = this.props;
    const options = [];
    policies.forEach((item) => {
      options.push(item.name);
    });
    return options;
  };

  getAdvancedSettingsForm = () => {
    const {
      classes,
      advancedSettings,
      isEditMode,
    } = this.props;
    const readOnlyWbListIconClasses = {
      root: classes.settings__globalWbListIcon,
    };
    return Object.keys(advancedSettings).map((key) => {
      const field = advancedSettings[key];
      return (
        <div
          className={classes.advancedSection__item}
          key={key}
        >
          {getPolicyEditFormComponent(
            field.type,
            {
              disabled: !isEditMode,
              handleChangeCheckbox: this.handleChangeCheckboxAdvancedSettings,
              handleChangeInput: this.handleChangeInputAdvancedSettings,
              handleChangeDropdown: this.handleChangeDropdownAdvancedSettings,
              handleChangeMultiDropdown: this.handleChangeMultiDropdownAdvancedSettings,
              label: field.name,
              name: key,
              values: field.values,
              value: this.getValidInputValue(field.value),
              readOnlyWbListIcon: <Language classes={readOnlyWbListIconClasses} />,
            },
          )}
        </div>
      );
    });
  };

  getBaseSettingsForm = () => {
    const {
      classes,
      baseSettings,
      isEditMode,
    } = this.props;
    const readOnlyWbListIconClasses = {
      root: classes.settings__globalWbListIcon,
    };
    return Object.keys(baseSettings).map((key) => {
      const field = baseSettings[key];
      return (
        <div
          className={classes.baseSection__item}
          key={key}
        >
          {getPolicyEditFormComponent(
            field.type,
            {
              disabled: !isEditMode,
              handleChangeCheckbox: this.handleChangeCheckboxBaseSettings,
              handleChangeInput: this.handleChangeInputBaseSettings,
              handleChangeDropdown: this.handleChangeDropdownBaseSettings,
              handleChangeMultiDropdown: this.handleChangeMultiDropdownBaseSettings,
              label: field.name,
              name: key,
              values: field.values,
              value: this.getValidInputValue(field.value),
              readOnlyWbListIcon: <Language classes={readOnlyWbListIconClasses} />,
            },
          )}
        </div>
      );
    });
  };

  getValidInputValue = (value) => {
    if (_isNull(value) || _isUndefined(value)) {
      return '';
    }
    return value;
  };

  handleChangePolicyTemplate = (e) => {
    this.props.onChangePolicyTemplate(e.target.value);
  };

  handleChangeInputBaseSettings = (e) => {
    const {baseSettings} = this.props;
    const newSettingObject = baseSettings[e.target.name];
    newSettingObject.value = e.target.value;
    this.props.onChangeBaseSettings(e.target.name, newSettingObject);
  };

  handleChangeCheckboxBaseSettings = (e, checked) => {
    const {baseSettings} = this.props;
    const newSettingObject = baseSettings[e.target.name];
    newSettingObject.value = _findIndex(
      baseSettings[e.target.name].values,
      (o) => _isEqual(o.name, checked.toString()),
    );
    this.props.onChangeBaseSettings(e.target.name, newSettingObject);
  };

  handleChangeDropdownBaseSettings = (e) => {
    const {baseSettings} = this.props;
    const newSettingObject = baseSettings[e.target.name];
    newSettingObject.value = e.target.value;
    this.props.onChangeBaseSettings(e.target.name, newSettingObject);
  };

  handleChangeMultiDropdownBaseSettings = (e) => {
    this.handleChangeDropdownBaseSettings(e);
  };

  handleChangeInputAdvancedSettings = (e) => {
    const {advancedSettings} = this.props;
    const newSettingObject = advancedSettings[e.target.name];
    newSettingObject.value = e.target.value;
    this.props.onChangeAdvancedSettings(e.target.name, newSettingObject);
  };

  handleChangeCheckboxAdvancedSettings = (e, checked) => {
    const {advancedSettings} = this.props;
    const newSettingObject = advancedSettings[e.target.name];
    newSettingObject.value = _findIndex(
      advancedSettings[e.target.name].values,
      (o) => _isEqual(o.name, checked.toString()),
    );
    this.props.onChangeAdvancedSettings(e.target.name, newSettingObject);
  };

  handleChangeDropdownAdvancedSettings = (e) => {
    const {advancedSettings} = this.props;
    const newSettingObject = advancedSettings[e.target.name];
    newSettingObject.value = e.target.value;
    this.props.onChangeAdvancedSettings(e.target.name, newSettingObject);
  };

  handleChangeMultiDropdownAdvancedSettings = (e) => {
    this.handleChangeDropdownAdvancedSettings(e);
  };

  showHideAdvancedSettings = () => {
    const {isShowAdvancedSection} = this.state;
    this.setState({
      isShowAdvancedSection: !isShowAdvancedSection,
    });
  };

  render() {
    const {
      classes,
      isEditMode,
      isReadOnly,
      selectedPolicyTemplateIndex,
    } = this.props;
    const {isShowAdvancedSection} = this.state;
    return (
      <div className={classes.settings}>
        <RenderOrEmpty isShow={!isReadOnly}>
          <div className={classes.settings__templateSection}>
            <Dropdown
              customSelectClass="dropdown__select_policySettingsTab"
              disabled={!isEditMode}
              label={I18n.t('policyPage.settingsTab.copyPolicySection.label')}
              onChangeValue={this.handleChangePolicyTemplate}
              options={this.getPolicyTemplateDropdownOptions()}
              selectedItemIndex={selectedPolicyTemplateIndex}
            />
          </div>
        </RenderOrEmpty>
        <div className={classes.settings__baseSection}>
          <CardWithHeader
            cardTitle={I18n.t('policyPage.settingsTab.baseSection.title')}
            cardTitleColor="primary"
            customCardHeaderClasses="card__header_policyEditForm"
            customCardContentClasses="card__content_policyEditForm"
            content={(
              <div className={classes.baseSection}>
                {this.getBaseSettingsForm()}
              </div>
            )}
          />
        </div>
        <div className={classes.settings__advancedSection}>
          <ButtonExpandLessMore
            customClasses="policySettingsTab__buttonShowHideAdvancedMode"
            isExpandLess={isShowAdvancedSection}
            onClick={this.showHideAdvancedSettings}
            text={I18n.t('policyPage.settingsTab.advancedSection.title')}
          />
          <RenderOrEmpty isShow={isShowAdvancedSection}>
            <div className={classes.advancedSection}>
              {this.getAdvancedSettingsForm()}
            </div>
          </RenderOrEmpty>
        </div>
      </div>
    );
  }
}

PolicySettingsTab.propTypes = {
  baseSettings: PropTypes.object.isRequired,
  advancedSettings: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  isEditMode: PropTypes.bool.isRequired,
  isReadOnly: PropTypes.bool.isRequired,
  policies: PropTypes.array.isRequired,
  selectedPolicyTemplateIndex: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]).isRequired,

  onChangePolicyTemplate: PropTypes.func.isRequired,
  onChangeBaseSettings: PropTypes.func.isRequired,
  onChangeAdvancedSettings: PropTypes.func.isRequired,
};

PolicySettingsTab.defaultProps = {};

export default withStyles(style)(PolicySettingsTab);
