import _get from 'lodash/get';

import {makeUrl, post} from '../../utils/fetcher';
import {METHODS} from '../../constants';
import {getFilters, getFiltersAndOptions} from '../../app-common/Insights/action';
import getLogsTableData from '../../services/logs/getLogsTableData';
import {getTabFilters} from '../../app-common/Insights/selectors';

export const types = {
  RESET_INSIGHTS_LOGS: 'RESET_INSIGHTS_LOGS',
  RESET_INSIGHTS_DOMAIN_LOGS: 'RESET_INSIGHTS_DOMAIN_LOGS',
  RESET_INSIGHTS_FQDN_LOGS: 'RESET_INSIGHTS_FQDN_LOGS',
  LOAD_MORE_INSIGHTS_LOGS: 'LOAD_MORE_INSIGHTS_LOGS',
  SET_INSIGHTS_LOGS: 'SET_INSIGHTS_LOGS',
  SET_DOMAIN_LOGS: 'SET_INSIGHTS_DOMAIN_LOGS',
  SET_FQDN_LOGS: 'SET_INSIGHTS_FQDN_LOGS',
};

function loadLogs(tab, data, actionType) {
  const url = makeUrl(METHODS.GET_INSIGHTS_LOGS);
  return (dispatch, getState) => {
    const state = getState();
    const body = {
      ...getFiltersAndOptions(tab, state),
      ...data,
    };
    const maxInspectionDays = _get(
      state,
      'operatorLayoutReducer.accountInfo.maxAuditResponseDays',
      null,
    );
    return post(url, body, dispatch, true)
      .then((response) => {
        if (response && response.data) {
          dispatch({
            type: types[actionType],
            logs: getLogsTableData(response.data, maxInspectionDays),
          });
        }
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.log('Unable to load insight logs:', error);
      });
  };
}

export function getLogs(tab, data) {
  return loadLogs(tab, data, types.SET_INSIGHTS_LOGS);
}

export function loadMoreLogs(tab, data) {
  return loadLogs(tab, data, types.LOAD_MORE_INSIGHTS_LOGS);
}

export function getDomainLogs(tab, data) {
  const url = makeUrl(METHODS.GET_DOMAIN_STATISTICS);

  return (dispatch, getState) => {
    const state = getState();
    const options = state.insightsReducer.searchOptions[tab];
    const filters = getFilters(getTabFilters(tab)(state));
    const params = {...options, ...filters, ...data};
    return post(url, params, dispatch, true)
      .then((response) => {
        if (response && response.data) {
          dispatch({
            type: types.SET_DOMAIN_LOGS,
            domains: response.data,
          });
        }
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.log('Unable to get domain statistics:', error);
      });
  };
}

export function getFqdnLogs(tab, data) {
  const url = makeUrl(METHODS.GET_FQDN_STATISTICS);

  return (dispatch, getState) => {
    const state = getState();
    const options = state.insightsReducer.searchOptions[tab];
    const filters = getFilters(getTabFilters(tab)(state));
    const params = {...options, ...filters, ...data};
    return post(url, params, dispatch, true)
      .then((response) => {
        if (response && response.data) {
          dispatch({
            type: types.SET_FQDN_LOGS,
            fqdns: response.data,
          });
        }
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.log('Unable to get FQDN statistics:', error);
      });
  };
}

export function resetLogs() {
  return (dispatch) => {
    dispatch({
      type: types.RESET_INSIGHTS_LOGS,
    });
  };
}

export function resetDomainLogs() {
  return (dispatch) => {
    dispatch({
      type: types.RESET_INSIGHTS_DOMAIN_LOGS,
    });
  };
}

export function resetFqdnLogs() {
  return (dispatch) => {
    dispatch({
      type: types.RESET_INSIGHTS_FQDN_LOGS,
    });
  };
}
