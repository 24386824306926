const style = {
  policiesAssignedTable__row: {
    height: '32px',
    lineHeight: '32px',
    borderBottom: 'solid 1px rgba(0, 0, 0, 0.05)',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },
  policiesAssignedTable__noPoliciesFound: {
    textAlign: 'center',
    marginTop: '20px',
    marginBottom: '20px',
    color: '#AAAAAA',
  },
};

export default style;
