import _isNull from 'lodash/isNull';
import _isEmpty from 'lodash/isEmpty';

import {
  TOP_CATEGORIES_COUNT,
  TOP_DOMAINS_COUNT,
  PERIODS,
} from '../constants';

import {
  formatDateTimeWithTCharAndTimezoneAbbrToUserTimezoneWithTChar,
} from './dateTime';

export function getValidData(data) {
  if (data && data !== 'NaN') {
    return data;
  }
  return 0;
}

export function getTopDomainsTableData(data) {
  return getTopStatsTableData(data, TOP_DOMAINS_COUNT);
}

export function getTopCategoriesTableData(data) {
  return getTopStatsTableData(data, TOP_CATEGORIES_COUNT);
}

export function getTopStatsTableData(data, defaultAmount) {
  const keys = Object.keys(data);
  let tableData = [];
  if (keys.length !== 0) {
    tableData = keys.map((key) => ([key, data[key]]));
    for (let i = 0; i < defaultAmount - keys.length; i++) {
      tableData.push(['', '']);
    }
    return tableData;
  }
  return tableData;
}

export function getRequestsPerNetworkTableData(data) {
  return Object.keys(data).map((item) => (
    [
      item,
      data[item].queriesPerSecond,
      data[item].allowed,
      data[item].blocked,
      data[item].allowed + data[item].blocked,
    ]
  ));
}

export function getPieChartData(data) {
  return Object.keys(data).map((key) => ({
    name: key,
    value: data[key],
  }));
}

function selectDateOrTime(dateTime, period) {
  // TODO: extract time and date with some library function.
  // eslint-disable-next-line max-len
  const dateTimeInUserTimezone = formatDateTimeWithTCharAndTimezoneAbbrToUserTimezoneWithTChar(dateTime);

  if (
    period === PERIODS.LAST_HOUR
    || period === 'Previous Hour'
    || period === PERIODS.TODAY
    || period === PERIODS.LAST_24_HOURS
    || period === PERIODS.YESTERDAY
  ) {
    return dateTimeInUserTimezone.slice(11, 19);
  }
  return dateTimeInUserTimezone.slice(0, 10);
}

export function getAllowedBlockedNetworkChartData(data, period) {
  const dates = Object.keys(data).sort();
  return dates.map((item) => ({
    xAxis: selectDateOrTime(item, period),
    allowed: data[item].allowed,
    blocked: data[item].blocked,
  }));
}

export function getNetworksStatsAndRequestsPerSecondStats(campusStats, campusId) {
  const result = {
    networksStats: {
      up: 0,
      down: 0,
    },
    requestsPerSecond: 0,
  };
  if (_isEmpty(campusStats)) {return result;}

  if (_isNull(campusId)) {
    const keys = Object.keys(campusStats);
    keys.forEach((item) => {
      result.networksStats.up += campusStats[item].networkUp;
      result.networksStats.down += campusStats[item].networkDown;
      result.requestsPerSecond += campusStats[item].requestsPerSecond;
    });
    return {
      ...result,
      requestsPerSecond: result.requestsPerSecond,
    };
  }
  result.networksStats.up = campusStats[campusId].networkUp;
  result.networksStats.down = campusStats[campusId].networkDown;
  result.requestsPerSecond = campusStats[campusId].requestsPerSecond;
  return result;
}

export function convertThreatNames(threats) {
  const namesMapping = {
    'Malicious Scripts': 'Mal. Scripts',
    'Infected Hosts': 'Inf. Hosts',
  };
  return threats.map((item) => ({
    ...item,
    threat: namesMapping[item.threat] || item.threat,
  }));
}
