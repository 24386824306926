import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {I18n} from 'react-redux-i18n';
import classNames from 'classnames';
import _isEqual from 'lodash/isEqual';
import _get from 'lodash/get';

import {withStyles} from '@material-ui/core/styles';

import {widgetKeys} from './constants';
import {
  BlocksStats,
  ClientCountStats,
  DashboardOperatorFiltersPanel,
  PerformanceStats,
  RenderDivOrEmpty,
  RequestsStats,
  StatusStats,
  ThreatStats,
  TopCategoriesCard,
  TopDomainsCard,
} from '../../../components';

import {getDashboardData} from './action';
import {getCampuses} from '../../../app-common/Campus/action';
import {cancelRequests} from '../../../containers/Loading/action';

import style from './style';

class Dashboard extends Component {
  static shouldReloadDashboard(prevProps, props) {
    return !_isEqual(prevProps.loggedAccount.accountId, props.loggedAccount.accountId)
      || !_isEqual(
        _get(prevProps.currentOrganization, 'id', null),
        _get(props.currentOrganization, 'id', null),
      );
  }

  constructor(props) {
    super(props);
    this.top5CategoriesTableHeaders = [
      I18n.t('dashboard.categories.categoryHeader'),
      I18n.t('dashboard.categories.countHeader'),
    ];
    this.top5DomainsTableHeaders = [
      I18n.t('dashboard.domains.domainHeader'),
      I18n.t('dashboard.domains.countHeader'),
    ];
  }

  componentDidMount() {
    this.props.getCampuses();
  }

  componentDidUpdate(prevProps) {
    if (Dashboard.shouldReloadDashboard(prevProps, this.props)) {
      this.props.getCampuses();
    }
  }

  componentWillUnmount() {
    this.interruptLoadingRequests(true);
  }

  handleRefresh = (refreshData) => {
    this.interruptLoadingRequests(false);
    this.props.getDashboardData(refreshData);
  };

  interruptLoadingRequests = (includePageKey) => {
    this.props.cancelRequests(Object.values(widgetKeys), includePageKey);
  };

  render() {
    const {
      averageLatency,
      blocksStats,
      campuses,
      classes,
      loggedAccount,
      accountInfo,
      currentOrganization,
      networksStats,
      requestsByDecision,
      requestsPerSecond,
      top5Categories,
      top5Domains,
      userRequestsGraph,
      clientCountStats,
      threats,
    } = this.props;

    const graphBlockClasses = classNames(
      classes.stats__block,
      {
        [classes.stats__block_graph_deviceAgentAllowed]: accountInfo.deviceAgentAllowed,
        [classes.stats__block_graph_deviceAgentNotAllowed]: !accountInfo.deviceAgentAllowed,
      },
    );
    const numericBlockClasses = classNames(
      classes.stats__block,
      {
        [classes.stats__block_numeric_deviceAgentAllowed]: accountInfo.deviceAgentAllowed,
        [classes.stats__block_numeric_deviceAgentNotAllowed]: !accountInfo.deviceAgentAllowed,
      },
    );
    const numericCardsClasses = classNames(
      classes.stats__numericCards,
      {
        [classes.stats__numericCards_deviceAgentAllowed]: accountInfo.deviceAgentAllowed,
        [classes.stats__numericCards_deviceAgentNotAllowed]: !accountInfo.deviceAgentAllowed,
      },
    );

    return (
      <div className={classes.dashboard}>
        <div className={classes.dashboard__filtersPanel}>
          <DashboardOperatorFiltersPanel
            campuses={campuses}
            handleRefresh={this.handleRefresh}
            loggedAccount={loggedAccount}
            currentOrganization={currentOrganization}
          />
        </div>
        <div className={classes.dashboard__stats}>
          <div className={numericBlockClasses}>
            <div className={numericCardsClasses}>
              <div className={classes.cardBlocks__block}>
                <StatusStats
                  networksUp={networksStats.up}
                  networksDown={networksStats.down}
                  widgetKey={widgetKeys.CAMPUSES_STATS}
                />
              </div>
              <div className={classes.cardBlocks__block}>
                <PerformanceStats
                  averageLatency={averageLatency}
                  requestsPerSecond={requestsPerSecond}
                  widgetKey={widgetKeys.CAMPUSES_STATS}
                />
              </div>
            </div>
            <RenderDivOrEmpty
              isShow={accountInfo.deviceAgentAllowed}
              className={numericCardsClasses}
            >
              <div className={classes.cardBlocks__block}>
                <ClientCountStats
                  online={clientCountStats.online}
                  offline={clientCountStats.offline}
                  widgetKey={widgetKeys.CLIENT_STATS}
                />
              </div>
              <div className={classes.cardBlocks__block}>
                <ThreatStats
                  stats={threats}
                  widgetKey={widgetKeys.THREAT_STATS}
                />
              </div>
            </RenderDivOrEmpty>
          </div>
          <div className={graphBlockClasses}>
            <RequestsStats
              userRequestsGraph={userRequestsGraph}
              requestsAllowed={requestsByDecision.ALLOWED}
              requestsBlocked={requestsByDecision.FORBIDDEN}
              requestsTotal={requestsByDecision.ALLOWED + requestsByDecision.FORBIDDEN}
              widgetKey={widgetKeys.REQUEST_STATS}
            />
          </div>
          <div
            className={classNames(
              classes.stats__block,
              classes.stats__block_bottom,
            )}
          >
            <BlocksStats
              applications={blocksStats.applications}
              content={blocksStats.content}
              whiteBlackList={blocksStats.whiteBlackList}
              threats={blocksStats.threats}
              widgetKey={widgetKeys.BLOCKED_STATS}
            />
          </div>
        </div>
        <div className={classes.dashboard__pairBlocks}>
          <div className={classes.dashboard__pairBlocks_first}>
            <TopCategoriesCard
              classes={classes}
              chartData={top5Categories.ALLOWED.chartData}
              headers={this.top5CategoriesTableHeaders}
              textWhenDataNotAvailable={I18n.t('dashboard.categories.dataNotAvailable')}
              title={I18n.t('dashboard.categories.allowedTitle')}
              titleColor="green"
              tableData={top5Categories.ALLOWED.tableData}
              widgetKey={widgetKeys.TOP_CATEGORIES_ALLOWED}
            />
          </div>
          <div className={classes.dashboard__pairBlocks_second}>
            <TopCategoriesCard
              classes={classes}
              chartData={top5Categories.FORBIDDEN.chartData}
              headers={this.top5CategoriesTableHeaders}
              textWhenDataNotAvailable={I18n.t('dashboard.categories.dataNotAvailable')}
              title={I18n.t('dashboard.categories.blockedTitle')}
              titleColor="red"
              tableData={top5Categories.FORBIDDEN.tableData}
              widgetKey={widgetKeys.TOP_CATEGORIES_FORBIDDEN}
            />
          </div>
        </div>
        <div className={classes.dashboard__pairBlocks}>
          <div className={classes.dashboard__pairBlocks_first}>
            <TopDomainsCard
              classes={classes}
              headers={this.top5DomainsTableHeaders}
              tableData={top5Domains.ALLOWED.tableData}
              textWhenDataNotAvailable={I18n.t('dashboard.domains.dataNotAvailable')}
              title={I18n.t('dashboard.domains.allowedTitle')}
              titleColor="green"
              widgetKey={widgetKeys.TOP_DOMAINS_ALLOWED}
            />
          </div>
          <div className={classes.dashboard__pairBlocks_second}>
            <TopDomainsCard
              classes={classes}
              headers={this.top5DomainsTableHeaders}
              tableData={top5Domains.FORBIDDEN.tableData}
              textWhenDataNotAvailable={I18n.t('dashboard.domains.dataNotAvailable')}
              title={I18n.t('dashboard.domains.blockedTitle')}
              titleColor="red"
              widgetKey={widgetKeys.TOP_DOMAINS_FORBIDDEN}
            />
          </div>
        </div>
      </div>
    );
  }
}

Dashboard.propTypes = {
  averageLatency: PropTypes.number.isRequired,
  blocksStats: PropTypes.object.isRequired,
  campuses: PropTypes.array.isRequired,
  classes: PropTypes.object.isRequired,
  loggedAccount: PropTypes.object.isRequired,
  accountInfo: PropTypes.object.isRequired,
  currentOrganization: PropTypes.object,
  networksStats: PropTypes.object.isRequired,
  requestsByDecision: PropTypes.object.isRequired,
  requestsPerSecond: PropTypes.number.isRequired,
  top5Categories: PropTypes.object.isRequired,
  top5Domains: PropTypes.object.isRequired,
  userRequestsGraph: PropTypes.object.isRequired,
  threats: PropTypes.array.isRequired,
  clientCountStats: PropTypes.object.isRequired,

  cancelRequests: PropTypes.func.isRequired,
  getDashboardData: PropTypes.func.isRequired,
  getCampuses: PropTypes.func.isRequired,
};

Dashboard.defaultProps = {
  currentOrganization: null,
};

const mapStateToProps = (state) => ({
  campuses: state.campusReducer.campuses,

  loggedAccount: state.userAccountsReducer.loggedAccount,
  accountInfo: state.operatorLayoutReducer.accountInfo,
  currentOrganization: state.userOrganizationsReducer.currentOrganization,

  averageLatency: state.dashboardReducer.averageLatency,
  blocksStats: state.dashboardReducer.blocksStats,
  networksStats: state.dashboardReducer.networksStats,
  requestsByDecision: state.dashboardReducer.requestsByDecision,
  requestsPerNetwork: state.dashboardReducer.requestsPerNetwork,
  requestsPerSecond: state.dashboardReducer.requestsPerSecond,
  top5Categories: state.dashboardReducer.top5Categories,
  top5Domains: state.dashboardReducer.top5Domains,
  userRequestsGraph: state.dashboardReducer.userRequestsGraph,
  threats: state.dashboardReducer.threats,
  clientCountStats: state.dashboardReducer.clientCountStats,
});

const mapDispatchToProps = (dispatch) => ({
  getDashboardData: bindActionCreators(getDashboardData, dispatch),
  getCampuses: bindActionCreators(getCampuses, dispatch),
  cancelRequests: bindActionCreators(cancelRequests, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(style)(Dashboard));
