import _cloneDeep from 'lodash/cloneDeep';
import _findIndex from 'lodash/findIndex';
import _isEqual from 'lodash/isEqual';
import _omit from 'lodash/omit';

export function getBlockPagesTableData(blockPages) {
  return blockPages.map((item) => ({
    blockPageName: item.name,
    blockPageDescription: item.description,
    blockPageDefault: item.default,
  }));
}

export function getBlockPageToCreateOrUpdate(newBlockPage) {
  const newData = _cloneDeep(newBlockPage);

  const fieldsToOmit = ['id'];
  const dataWithOmittedFields = _omit(newData, fieldsToOmit);

  return dataWithOmittedFields;
}

export function prepareBlockPageData(blockPage) {
  let newData = _cloneDeep(blockPage);

  newData = {
    ...newData,
    threatLogoMessage: newData.contentLogoMessage,
    threatNonDefaultEmail: newData.contentNonDefaultEmail,
    threatHasReviewRequests: newData.contentHasReviewRequests,
    threatShouldHideIPs: newData.contentShouldHideIPs,
    threatHasUserEmailInput: newData.contentHasUserEmailInput,
    threatHasNonDefaultSendEmail: newData.contentHasNonDefaultSendEmail,
    threatHasLogoInserted: newData.contentHasLogoInserted,
    contentLogoURL: newData.contentHasLogoInserted ? newData.contentLogoURL : '',
    threatLogoURL: newData.contentHasLogoInserted ? newData.contentLogoURL : '',
    threatLogoName: newData.contentLogo && newData.contentHasLogoInserted
      ? newData.contentLogo.name
      : '',
    contentLogoName: newData.contentLogo && newData.contentHasLogoInserted
      ? newData.contentLogo.name
      : '',
  };

  const fieldsToOmit = ['default', 'contentLogo'];
  const dataWithOmittedFields = _omit(newData, fieldsToOmit);

  return dataWithOmittedFields;
}

export function getUpdatedBlockPageIndex(updatedBlockPageId, blockPages) {
  return _findIndex(blockPages, (o) => _isEqual(o.id, updatedBlockPageId));
}
