import React from 'react';
import {I18n} from 'react-redux-i18n';

import {v4 as uuid} from 'uuid';
import _isUndefined from 'lodash/isUndefined';
import _isEqual from 'lodash/isEqual';

import {
  Dropdown,
  MultiDropdown,
  Switch,
} from '../index';

import {
  CustomInput,
} from '../../material-dashboard-pro-react/components/index';

import {POLICY_FORM_COMPONENTS_TYPES} from '../../constants';

const componentsMap = new Map([
  [
    POLICY_FORM_COMPONENTS_TYPES.CHECKBOX,
    (data) => (
      <Switch
        disabled={data.disabled}
        isChecked={_isEqual(data.values[data.value].name, 'true')}
        label={data.label}
        labelClassName="switch__label_policySettings"
        labelFormClassName="formControl_noMargin"
        name={data.name}
        onChange={data.handleChangeCheckbox}
      />
    ),
  ],
  [
    POLICY_FORM_COMPONENTS_TYPES.DROPDOWN,
    (data) => {
      const localizedData = getDropdownLocalizedData(data);
      return (
        <Dropdown
          customSelectClass="dropdown__select_policySettingsTab"
          disabled={data.disabled}
          dropdownName={data.name}
          fullWidth={true}
          label={localizedData.label}
          onChangeValue={data.handleChangeDropdown}
          options={localizedData.values.map((item) => item.name)}
          selectedItemIndex={data.value}
        />
      );
    },
  ],
  [
    POLICY_FORM_COMPONENTS_TYPES.MULTI_DROPDOWN,
    (data) => (
      <MultiDropdown
        customSelectClass="multiDropdown__select_policySettingsTab"
        disabled={data.disabled}
        multiDropdownName={data.name}
        fullWidth={true}
        label={
          I18n.t(
            'policyPage.multiSelectionLabel',
            {label: I18n.t(`policyPage.fields.${data.label}`)},
          )
        }
        onChangeValue={data.handleChangeMultiDropdown}
        options={data.values}
        selectedItemsIndexes={data.value}
        readOnlyItemIcon={data.readOnlyWbListIcon}
      />
    ),
  ],
  [
    POLICY_FORM_COMPONENTS_TYPES.INPUT,
    (data) => {
      const formControlProps = {
        fullWidth: true,
      };
      const inputProps = {
        disabled: data.disabled,
        name: data.name,
        onChange: data.handleChangeInput,
        type: 'text',
        value: data.value,
      };
      return (
        <CustomInput
          formControlProps={formControlProps}
          labelText={data.label}
          id={uuid()}
          inputProps={inputProps}
        />
      );
    },
  ],
]);

export default function getPolicyEditFormComponent(key, data) {
  const getComponent = componentsMap.get(key);
  if (!_isUndefined(getComponent)) {
    return getComponent(data);
  }
  return null;
}

function getDropdownLocalizedData(componentData) {
  const nameLowerCase = componentData.name.toLowerCase();
  if (isThereLocalization(nameLowerCase)) {
    return {
      label: I18n.t(`policyPage.formComponents.${nameLowerCase}.label`),
      values: componentData.values.map((option) => ({
        name: I18n.t(`policyPage.formComponents.${nameLowerCase}.${option.name.toLowerCase()}`),
      })),
    };
  }
  return componentData;
}

function isThereLocalization(componentName) {
  return !_isEqual(I18n.t(`policyPage.formComponents.${componentName}`), componentName);
}
