import React from 'react';
import PropTypes from 'prop-types';
import _pullAt from 'lodash/pullAt';
import {withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {I18n} from 'react-redux-i18n';
import {updateEditOrganizationSites} from './action';
import {insertIntoSortedArray} from '../../utils/array';
import {SelectableEntityVerticalPairedList} from '../../components/index';

class OrganizationSitesEditForm extends React.Component {
  componentDidMount() {
    this.props.updateEditOrganizationSites(
      this.props.sitesTabSitesInOrganization,
      this.props.sitesTabSitesNotInOrganization,
    );
  }

  moveToInOrganization = (indices) => {
    const sitesInOrganization = this.props.sitesInOrganization;
    const sitesNotInOrganization = this.props.sitesNotInOrganization;
    const siteLists = this.moveItemsBetweenLists(
      sitesNotInOrganization,
      sitesInOrganization,
      indices,
    );
    this.props.updateEditOrganizationSites(siteLists.destination, siteLists.source);
  };

  moveToNotInOrganization = (indices) => {
    const sitesInOrganization = this.props.sitesInOrganization;
    const sitesNotInOrganization = this.props.sitesNotInOrganization;
    const siteLists = this.moveItemsBetweenLists(
      sitesInOrganization,
      sitesNotInOrganization,
      indices,
    );
    this.props.updateEditOrganizationSites(siteLists.source, siteLists.destination);
  };

  moveItemsBetweenLists = (source, destination, indices) => {
    const newSource = [...source];
    const itemsToMove = _pullAt(newSource, indices);
    const newDestination = insertIntoSortedArray(destination, itemsToMove, 'ranking');
    return {
      source: newSource,
      destination: newDestination,
    };
  };

  render() {
    const {
      isEditMode,
      sitesInOrganization,
      sitesNotInOrganization,
    } = this.props;

    return (
      <SelectableEntityVerticalPairedList
          handleMoveFromLeftToRightList={this.moveToInOrganization}
          handleMoveFromRightToLeftList={this.moveToNotInOrganization}
          isEditMode={isEditMode}
          leftList={sitesNotInOrganization}
          rightList={sitesInOrganization}
          titleLeft={I18n.t('organizations.tabs.sites.notInOrganizationLabel')}
          titleRight={I18n.t('organizations.tabs.sites.inOrganizationLabel')}
      />

    );
  }
}

OrganizationSitesEditForm.propTypes = {
  isEditMode: PropTypes.bool.isRequired,
  sitesNotInOrganization: PropTypes.array.isRequired,
  sitesInOrganization: PropTypes.array.isRequired,
  updateEditOrganizationSites: PropTypes.func.isRequired,
  sitesTabSitesInOrganization: PropTypes.func.isRequired,
  sitesTabSitesNotInOrganization: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  sitesTabSitesInOrganization: state.organizationSitesTabReducer.sitesInOrganization,
  sitesTabSitesNotInOrganization: state.organizationSitesTabReducer.sitesNotInOrganization,
  isEditMode: state.organizationSitesTabReducer.isEditMode,
  sitesInOrganization: state.organizationSitesEditFormReducer.sitesInOrganization,
  sitesNotInOrganization: state.organizationSitesEditFormReducer.sitesNotInOrganization,
});

const mapDispatchToProps = (dispatch) => ({
  updateEditOrganizationSites: bindActionCreators(updateEditOrganizationSites, dispatch),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(OrganizationSitesEditForm));
