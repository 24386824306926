import ReactDOM from 'react-dom';
import {createStore, applyMiddleware, compose} from 'redux';
import {loadTranslations, setLocale, syncTranslationWithStore} from 'react-redux-i18n';
import thunk from 'redux-thunk';
import './material-dashboard-pro-react/assets/scss/material-dashboard-pro-react.css';

import {translationObject} from './translationObject';
import rootReducer from './reducers/root-reducer';

import {getManagementType} from './utils/tenants';

import {getApplication as getScoutDnsApplication} from './scout-dns/application';
import {getApplication as getOemApplication} from './oem/application';
import {MANAGEMENT_TYPES} from './constants';

const store = createStore(rootReducer, compose(applyMiddleware(thunk)));
syncTranslationWithStore(store);
store.dispatch(loadTranslations(translationObject));
store.dispatch(setLocale('en_EN'));

// for debugging
window.__store__ = store;

const applicationMap = {
  [MANAGEMENT_TYPES.OEM]: getOemApplication,
  [MANAGEMENT_TYPES.SCOUT_DNS]: getScoutDnsApplication,
};

ReactDOM.render(
  applicationMap[getManagementType()](store),
  document.getElementById('root'),
);
