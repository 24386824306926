import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import _isEqual from 'lodash/isEqual';

import {
  ContainerWithListAndForm,
  PolicyTable,
  PolicyCreating,
  PolicyDetails,
} from '../../components';

import {
  changeEditModeAndPolicyState,
  createPolicy,
  deletePolicy,
  getDataToShowPolicyDetails,
  getPolicies,
  getPoliciesTableData,
  resetSelectedPolicyIndex,
  setPolicyToFormCreating,
  updatePolicy,
} from './action';

import {
  COMPRESSED_MODE_TABLE as COMPRESSED,
  FULL_WIDTH_MODE_TABLE as FULL_WIDTH,
  MODES_SHOWING_CONTAINER_WITH_LIST_AND_FORM,
  STATES_ENTITY,
} from '../../constants';
import {getTabFormSchemes} from '../../containers/PolicyEditForm/action';

class Policy extends Component {
  static initialLocalState = {
    modeShowingContainer: MODES_SHOWING_CONTAINER_WITH_LIST_AND_FORM.SHOW_LIST_FULL_SCREEN,
    tableMode: FULL_WIDTH,
  };

  static wasDataScopeSwitched(prevProps, props) {
    return !_isEqual(prevProps.loggedAccount.accountId, props.loggedAccount.accountId)
      || !_isEqual(prevProps.currentOrganization, props.currentOrganization);
  }

  static shouldUpdatePolicyTableData(prevProps, props) {
    return !_isEqual(prevProps.policies, props.policies);
  }

  constructor(props) {
    super(props);
    this.state = Policy.initialLocalState;
  }

  componentDidMount() {
    this.props.getTabFormSchemes();
    this.props.getPolicies()
      .then(() => {
        this.props.getPoliciesTableData();
      });
  }

  componentDidUpdate(prevProps) {
    if (Policy.wasDataScopeSwitched(prevProps, this.props)) {
      this.resetLocalState();
      this.props.getPolicies();
    }
    if (Policy.shouldUpdatePolicyTableData(prevProps, this.props)) {
      this.props.getPoliciesTableData();
    }
  }

  componentWillUnmount() {
    this.props.resetSelectedPolicyIndex();
  }

  getContainerItemByModeShowingContainer = (modeShowingContainer) => {
    const {
      selectedPolicy,
      isEditMode,
    } = this.props;

    let containerItem = null;
    switch (modeShowingContainer) {
      case MODES_SHOWING_CONTAINER_WITH_LIST_AND_FORM.SHOW_LIST_FULL_SCREEN:
        containerItem = null;
        break;
      case MODES_SHOWING_CONTAINER_WITH_LIST_AND_FORM.SHOW_LIST_AND_CREATING_FORM:
        containerItem = (
          <PolicyCreating
            handleClickButtonCancel={this.handleClickButtonCancelCreateNewPolicy}
            handleClickButtonSave={this.handleClickButtonSaveNewPolicy}
          />
        );
        break;
      case MODES_SHOWING_CONTAINER_WITH_LIST_AND_FORM.SHOW_LIST_AND_ENTITY_DETAILS:
        containerItem = (
          <PolicyDetails
            isEditMode={isEditMode}
            policyInfo={selectedPolicy}
            handleClickButtonBack={this.goToPoliciesLists}
            handleClickButtonCancel={this.handleClickButtonCancelPolicyEditing}
            handleClickButtonDelete={this.handleClickButtonDeletePolicy}
            handleClickButtonEdit={this.handleClickButtonEditPolicy}
            handleClickButtonSave={this.savePolicy}
          />
        );
        break;
      default:
        break;
    }
    return containerItem;
  };

  resetLocalState = () => this.setState(Policy.initialLocalState);

  handleClickButtonSaveNewPolicy = () => {
    const {
      advancedSettingsObject,
      baseSettingsObject,
      categoriesObject,
      selectedPolicy,
    } = this.props;
    this.props.createPolicy(
      selectedPolicy,
      {
        advancedSettingsObject: advancedSettingsObject,
        baseSettingsObject: baseSettingsObject,
        categoriesObject: categoriesObject,
      },
    ).then((isSuccess) => {
      if (isSuccess) {
        this.goToPoliciesLists();
      }
    });
  };

  handleClickButtonCancelCreateNewPolicy = () => {
    this.goToPoliciesLists();
    this.props.changeEditModeAndPolicyState(false, STATES_ENTITY.EDITING_CANCELED);
  };

  handleClickButtonCancelPolicyEditing = () => {
    this.props.changeEditModeAndPolicyState(false, STATES_ENTITY.EDITING_CANCELED);
  };

  handleClickButtonEditPolicy = () => {
    this.props.changeEditModeAndPolicyState(true, STATES_ENTITY.EDITING);
  };

  savePolicy = () => {
    const {
      advancedSettingsObject,
      baseSettingsObject,
      categoriesObject,
      selectedPolicy,
    } = this.props;
    this.props.updatePolicy(
      selectedPolicy,
      {
        advancedSettingsObject: advancedSettingsObject,
        baseSettingsObject: baseSettingsObject,
        categoriesObject: categoriesObject,
      },
    );
  };

  handleClickButtonDeletePolicy = () => {
    const {selectedPolicy} = this.props;
    this.props.deletePolicy(selectedPolicy.id)
      .then((isSuccess) => {
        if (isSuccess) {
          this.goToPoliciesLists();
        }
      });
  };

  showFormCreatingPolicy = () => {
    this.setState({
      modeShowingContainer: MODES_SHOWING_CONTAINER_WITH_LIST_AND_FORM.SHOW_LIST_AND_CREATING_FORM,
      tableMode: COMPRESSED,
    });
    this.props.changeEditModeAndPolicyState(true, STATES_ENTITY.CREATING);
    this.props.resetSelectedPolicyIndex();
    this.props.setPolicyToFormCreating();
  };

  showPolicyDetails = (policyIndex) => {
    const {policies} = this.props;
    this.setState({
      modeShowingContainer: MODES_SHOWING_CONTAINER_WITH_LIST_AND_FORM.SHOW_LIST_AND_ENTITY_DETAILS,
      tableMode: COMPRESSED,
    });
    this.props.getDataToShowPolicyDetails(policies[policyIndex].id, policyIndex);
  };

  goToPoliciesLists = () => {
    this.setState(Policy.initialLocalState);
    this.props.resetSelectedPolicyIndex();
  };

  render() {
    const {
      isEditMode,
      policiesTableData,
      selectedPolicyIndex,
    } = this.props;
    const {
      modeShowingContainer,
      tableMode,
    } = this.state;
    return (
      <ContainerWithListAndForm
        list={(
          <PolicyTable
            handleClickButtonNew={this.showFormCreatingPolicy}
            handleClickCampusRow={this.showPolicyDetails}
            isEditMode={isEditMode}
            mode={tableMode}
            selectedPolicyIndex={selectedPolicyIndex}
            tableData={policiesTableData}
          />
        )}
        form={this.getContainerItemByModeShowingContainer(modeShowingContainer)}
      />
    );
  }
}

Policy.propTypes = {
  // eslint-disable-next-line react/no-unused-prop-types
  loggedAccount: PropTypes.object.isRequired,
  // eslint-disable-next-line react/no-unused-prop-types
  currentOrganization: PropTypes.object,

  advancedSettingsObject: PropTypes.object.isRequired,
  baseSettingsObject: PropTypes.object.isRequired,
  categoriesObject: PropTypes.object.isRequired,

  isEditMode: PropTypes.bool.isRequired,
  policies: PropTypes.array.isRequired,
  policiesTableData: PropTypes.array.isRequired,
  selectedPolicy: PropTypes.object.isRequired,
  selectedPolicyIndex: PropTypes.number.isRequired,

  changeEditModeAndPolicyState: PropTypes.func.isRequired,
  createPolicy: PropTypes.func.isRequired,
  deletePolicy: PropTypes.func.isRequired,
  getDataToShowPolicyDetails: PropTypes.func.isRequired,
  getPolicies: PropTypes.func.isRequired,
  getPoliciesTableData: PropTypes.func.isRequired,
  resetSelectedPolicyIndex: PropTypes.func.isRequired,
  setPolicyToFormCreating: PropTypes.func.isRequired,
  updatePolicy: PropTypes.func.isRequired,
  getTabFormSchemes: PropTypes.func.isRequired,
};

Policy.defaultProps = {
  currentOrganization: null,
};

const mapStateToProps = (state) => ({
  advancedSettingsObject: state.policyEditFormReducer.advancedSettingsObject,
  baseSettingsObject: state.policyEditFormReducer.baseSettingsObject,
  categoriesObject: state.policyEditFormReducer.categoriesObject,

  loggedAccount: state.userAccountsReducer.loggedAccount,
  currentOrganization: state.userOrganizationsReducer.currentOrganization,

  isEditMode: state.policyReducer.isEditMode,
  policies: state.policyReducer.policies,
  policiesTableData: state.policyReducer.policiesTableData,
  selectedPolicy: state.policyReducer.selectedPolicy,
  selectedPolicyIndex: state.policyReducer.selectedPolicyIndex,
});

const mapDispatchToProps = (dispatch) => ({
  changeEditModeAndPolicyState: bindActionCreators(changeEditModeAndPolicyState, dispatch),
  createPolicy: bindActionCreators(createPolicy, dispatch),
  deletePolicy: bindActionCreators(deletePolicy, dispatch),
  getDataToShowPolicyDetails: bindActionCreators(getDataToShowPolicyDetails, dispatch),
  getPolicies: bindActionCreators(getPolicies, dispatch),
  getPoliciesTableData: bindActionCreators(getPoliciesTableData, dispatch),
  resetSelectedPolicyIndex: bindActionCreators(resetSelectedPolicyIndex, dispatch),
  setPolicyToFormCreating: bindActionCreators(setPolicyToFormCreating, dispatch),
  updatePolicy: bindActionCreators(updatePolicy, dispatch),
  getTabFormSchemes: bindActionCreators(getTabFormSchemes, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Policy);
